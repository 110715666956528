import clsx from "clsx";
import Select from "react-select";

export default function MultiSelectCommon({
  options,
  placeholder,
  handleChange,
  isSearchable,
  name,
  isDisabled = false,
  value,
  isSmall = false,
  optionWithStyle = () => {},
  customOptionStyle = () => {},
}) {
  // const isDisabled = false; // Or pass this as a prop or state
  // Create a reference for the select input

  const controlStyles = {
    base: `border relative rounded-lg bg-white overflow-y-auto py-0 scroll-smooth ${
      isSmall ? "max-h-[28px]" : "max-h-[62px]"
    } ${isDisabled ? "opacity-75 cursor-not-allowed" : "cursor-pointer"}`,
    focus: "border-gray-400 ring-1 ring-gray-500",
    nonFocus: "border-gray-300 hover:border-gray-400",
  };
  const placeholderStyles = "text-gray-500 pl-1 py-0.5";
  const selectInputStyles = "pl-1 py-0.5";
  const valueContainerStyles = "p-1 gap-1";
  const singleValueStyles = "leading-7 ml-1";
  const multiValueStyles =
    "bg-gray-100 rounded items-center pl-2 pr-1 gap-1.5 bg-orange-300 h-6";
  const multiValueLabelStyles = "leading-6 py-0.5 ";
  const multiValueRemoveStyles =
    "border border-gray-200 bg-white hover:bg-red-50 hover:text-red-800 text-gray-500 hover:border-red-300 rounded-md";
  const indicatorsContainerStyles = "px-2 gap-1";
  const clearIndicatorStyles = `multi-select-sticky ${
    isSmall ? "top-[4px] bottom-[4px]" : "top-[15px] bottom-[17px]"
  } text-gray-500 p-1 rounded-md hover:bg-red-50 hover:text-red-800`;
  const indicatorSeparatorStyles = "bg-gray-300";
  const dropdownIndicatorStyles = `multi-select-sticky ${
    isSmall ? "top-[4px] bottom-[4px]" : "top-[15px] bottom-[17px]"
  } p-1 hover:bg-gray-100 text-gray-500 rounded-md hover:text-black`;
  const menuStyles = "p-1 mt-2 border border-gray-200 bg-white rounded-lg";
  const groupHeadingStyles = "ml-3 mt-2 mb-1 text-gray-500 text-sm";
  const optionStyles = {
    base: "hover:cursor-pointer px-3 py-2 rounded",
    focus: "bg-orange-100 active:bg-orange-400",
    selected: "after:text-orange-400 text-gray-500",
  };
  const noOptionsMessageStyles =
    "text-gray-500 p-2 bg-gray-50 border border-dashed border-gray-200 rounded-sm";

  const selectedValues = value?.map((val) =>
    options?.find((option) => option?.value === val)
  );

  return (
    <Select
      isMulti
      unstyled
      options={options}
      value={selectedValues}
      name={name}
      onChange={handleChange}
      isSearchable={isSearchable}
      className={`min-w-[200px] text-sm select-none`}
      getOptionLabel={(e) => optionWithStyle(e)}
      classNames={{
        control: ({ isFocused }) =>
          clsx(
            isFocused ? controlStyles.focus : controlStyles.nonFocus,
            controlStyles.base
          ),
        placeholder: () => placeholderStyles,
        input: () => selectInputStyles,
        valueContainer: () => valueContainerStyles,
        singleValue: () => singleValueStyles,
        multiValue: () => multiValueStyles,
        multiValueLabel: () => multiValueLabelStyles,
        multiValueRemove: () => multiValueRemoveStyles,
        indicatorsContainer: () => indicatorsContainerStyles,
        clearIndicator: () => clearIndicatorStyles,
        indicatorSeparator: () => indicatorSeparatorStyles,
        dropdownIndicator: () => dropdownIndicatorStyles,
        menu: () => menuStyles,
        groupHeading: () => groupHeadingStyles,
        option: ({ isFocused, isSelected, data }) => {
          return (
            clsx(
              isFocused && optionStyles.focus,
              isSelected && optionStyles.selected,
              optionStyles.base
            ),
            customOptionStyle(
              data,
              clsx(
                isFocused && optionStyles.focus,
                isSelected && optionStyles.selected,
                optionStyles.base
              )
            )
          );
        },
        noOptionsMessage: () => noOptionsMessageStyles,
      }}
      placeholder={placeholder}
      isDisabled={isDisabled}
    />
  );
}
