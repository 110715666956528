import { Dialog, Tab, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { fetchFromRestApi } from "Axios/fetchFromRestApi";
import SpinnerButton from "Components/Common/Fields/SpinnerButton";
import useEscapeClose from "Components/Common/Hooks/useEscapeClose";
import {
  communityFeatures,
  exteriorFeatures,
  filterCountOptions,
  financing,
  homeTypes,
  houseStyle,
  houseView,
  interiorFeatures,
  lotSizeOptions,
  propertyFeature,
  squareFeetOptions,
  waterFront,
  yearBuiltOptions,
} from "Components/Leads/assetData";
import { useFormik } from "formik";
import {
  cleanObject,
  createObjectFromArray,
  getEmailOptions,
  isValidArray,
  isValidObject,
  showErrorMsg,
  showSuccessMsg,
  toQueryParams,
} from "Modules/util";
import { addSavePropertyValidation } from "Modules/validation";
import { Fragment, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useDomainListQuery } from "Redux/Leads/lead";
import { useAddPropertySearchEAlertMutation } from "Redux/Leads/PropertySearch/propertySearch";
import AddPropertyFilter from "./AddPropertyFilter/AddPropertyFilter";
import MapComponent from "./PropertyMapSearch";
import PropertySearchBar from "./PropertySearchBar";

export default function PropertyFilterModal(props) {
  const {
    isOpen,
    setIsOpen,
    lead,
    setIsRefetchPropertySearch = () => {},
    editPropertyData,
    isEditPropertyData = false,
    setIsEditPropertyData = () => {},
    setEditPropertyData = () => {},
  } = props;
  useEscapeClose(setIsOpen, false, handleModalClose); // Custom hook to close a modal when the Escape key is pressed.
  const [searchValue, setSearchValue] = useState({ search: "" });
  const [polygonValues, setPolygoneValues] = useState([]);
  const [propertyCount, setPropertyCount] = useState(0);
  const [selectedTab, setSelectedTab] = useState(0);
  const [saveAndSendMailAPICall, setSaveAndSendMailAPICall] = useState(false);

  const [addPropertySearch, { isLoading }] =
    useAddPropertySearchEAlertMutation(); // save property filter mutation
  const loginUser = useSelector((state) => state?.user?.currentUser); //getting current logged in user
  const { data: domainList } = useDomainListQuery();
  const domainOptions = [...getEmailOptions(domainList, loginUser)];

  useEffect(() => {
    if (saveAndSendMailAPICall) {
      formik?.handleSubmit();
    }
  }, [saveAndSendMailAPICall]);

  function handleModalClose() {
    formik?.handleReset();
    setSearchValue({ search: "" });
    setIsOpen(false);
    setPolygoneValues([]);
    setIsEditPropertyData(false);
    setEditPropertyData({});
    setPropertyCount(0);
    setSaveAndSendMailAPICall(false);
  }

  useEffect(() => {
    if (isValidObject(editPropertyData) && editPropertyData?.search) {
      setSearchValue({
        search: editPropertyData?.search,
        type: editPropertyData?.type,
      });
      setSelectedTab(1);
    } else {
      setSelectedTab(0);
    }
  }, [editPropertyData]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      minPrice:
        isEditPropertyData && editPropertyData?.minimum
          ? editPropertyData?.minimum
          : "",
      maxPrice:
        isEditPropertyData && editPropertyData?.maximum
          ? editPropertyData?.maximum
          : "",
      subject:
        isEditPropertyData && editPropertyData?.subject
          ? editPropertyData?.subject
          : "",
      ...createPropertySearchInitialValue(editPropertyData ?? []),

      lotSizeMin:
        isEditPropertyData && editPropertyData?.lotSizeMin
          ? editPropertyData?.lotSizeMin?.toString()
          : "any",
      lotSizeMax:
        isEditPropertyData && editPropertyData?.lotSizeMax
          ? editPropertyData?.lotSizeMax?.toString()
          : "any",
      bedrooms:
        isEditPropertyData && editPropertyData?.bedrooms
          ? editPropertyData?.bedrooms?.toString()
          : "any",
      bathrooms:
        isEditPropertyData && editPropertyData?.bathrooms
          ? editPropertyData?.bathrooms?.toString()
          : "any",
      yearBuiltMin:
        isEditPropertyData && editPropertyData?.yearBuiltMin
          ? editPropertyData?.yearBuiltMin?.toString()
          : "any",
      yearBuiltMax:
        isEditPropertyData && editPropertyData?.yearBuiltMax
          ? editPropertyData?.yearBuiltMax?.toString()
          : "any",
      storiesMin:
        isEditPropertyData && editPropertyData?.storiesMin
          ? editPropertyData?.storiesMin?.toString()
          : "any",
      storiesMax:
        isEditPropertyData && editPropertyData?.storiesMax
          ? editPropertyData?.storiesMax?.toString()
          : "any",
      squareFeetMin:
        isEditPropertyData && editPropertyData?.squareFeetMin
          ? editPropertyData?.squareFeetMin?.toString()
          : "any",
      squareFeetMax:
        isEditPropertyData && editPropertyData?.squareFeetMax
          ? editPropertyData?.squareFeetMax?.toString()
          : "any",
      garageSpacesMin:
        isEditPropertyData && editPropertyData?.garageSpacesMin
          ? editPropertyData?.garageSpacesMin?.toString()
          : "any",
      garageSpacesMax:
        isEditPropertyData && editPropertyData?.garageSpacesMax
          ? editPropertyData?.garageSpacesMax?.toString()
          : "any",
      sentBy:
        isEditPropertyData && editPropertyData?.sentBy
          ? editPropertyData?.sentBy
          : loginUser?.propertyFromEmail?.toLowerCase()
          ? loginUser?.propertyFromEmail?.toLowerCase()
          : !domainOptions?.[0]?.hasOwnProperty("isDisabled")
          ? domainOptions?.[0]?.value
          : domainOptions?.[1]?.value
          ? domainOptions?.[1]?.value
          : "",
      frequency:
        isEditPropertyData && editPropertyData?.frequency
          ? editPropertyData?.frequency
          : "instantly",
      emailsToSend:
        isEditPropertyData && editPropertyData?.emailsToSend
          ? editPropertyData?.emailsToSend
          : [],
    },
    validationSchema: addSavePropertyValidation(),
    onSubmit: async (values) => {
      if (!values?.subject) {
        setSaveAndSendMailAPICall(false);
        formik?.setFieldError("subject", "Please Enter Subject");
        showErrorMsg("Please Enter Subject");
      } else if (!isValidArray(values?.emailsToSend)) {
        setSaveAndSendMailAPICall(false);
        formik?.setFieldError("emailsToSend", "Please Select/Add Email");
        showErrorMsg("Please Select/Add Email");
      } else {
        const payload = {
          ...createPayload(values),
          ...(selectedTab === 1
            ? {
                polygon: "[]",
                type: searchValue?.type ?? "",
                search: searchValue?.search ?? "",
              }
            : {}),
          ...(selectedTab === 0
            ? {
                polygon: JSON?.stringify(polygonValues),
                type: "",
                search: "",
              }
            : {}),
          ...(isEditPropertyData ? { id: editPropertyData?._id } : {}),
          ...(saveAndSendMailAPICall
            ? { isSendEmail: true }
            : { isSendEmail: false }),
          agentId: lead?.agentResponse?.[0]?._id,
        };
        try {
          const response = await addPropertySearch(payload);
          const result = response?.data?.data?.addPropertySearchEAlert;
          if (isValidObject(result)) {
            const { type, message } = result;
            if (type === "success") {
              showSuccessMsg(result.message);
              setIsRefetchPropertySearch(true);
              handleModalClose();
              setSaveAndSendMailAPICall(false);
            } else if (type === "error") {
              showErrorMsg(message);
            }
          } else {
            showErrorMsg("Error occurred while add/edit property search");
          }
        } catch (error) {
          showErrorMsg("Error occurred while add/edit property search");
        } finally {
          setSaveAndSendMailAPICall(false);
        }
      }
    },
  });

  // create initial values
  function createPropertySearchInitialValue(dataObj) {
    let filterData = [];
    const filtersList = [
      "communityFeaturesFilter",
      "exteriorFeaturesFilter",
      "financingFilter",
      "interiorFeaturesFilter",
      "homeType",
      "propertyFeaturesFilter",
      "styleFilter",
      "viewFilter",
      "waterFrontFilter",
    ];

    filtersList?.forEach((key) => {
      if (dataObj?.hasOwnProperty(key)) {
        filterData = filterData?.concat(dataObj[key]);
      }
    });

    return { ...dataObj, ...createObjectFromArray(filterData) };
  }

  // Fetch Data from API
  const fetchData = async (payload) => {
    try {
      const data = await fetchFromRestApi(
        `/api/property/lists?${payload}&sort=newest`
      );
      setPropertyCount(data?.data?.totalRecords ?? 0);
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  function createPayload(values) {
    // Extract selected Home Types
    const selectedHomeTypes = homeTypes?.filter((home) => values?.[home]);
    // Extract selected Property Features
    const selectedPropertyFeatures = propertyFeature?.filter(
      (property) => values?.[property]
    );
    // Extract selected Community Features
    const selectedCommunityFeatures = communityFeatures?.filter(
      (community) => values?.[community]
    );
    // Extract selected Exterior Features
    const selectedExteriorFeatures = exteriorFeatures?.filter(
      (exterior) => values?.[exterior]
    );
    // Extract selected Interior Features
    const selectedInteriorFeatures = interiorFeatures?.filter(
      (interior) => values?.[interior]
    );
    // Extract selected House Style
    const selectedStyle = houseStyle?.filter((style) => values?.[style]);
    // Extract selected House View
    const selectedView = houseView?.filter((view) => values?.[view]);
    // Extract selected Water Front
    const selectedWaterFront = waterFront?.filter(
      (waterfront) => values?.[waterfront]
    );
    // Extract selected Financing
    const selectedFinancing = financing?.filter((finance) => values?.[finance]);

    // Construct payload
    const payload = {
      leadId: lead?.leadResponse?.[0]?._id || null, // Ensure a default value
      minimum: `${values?.minPrice}` || null, // Default to null if not provided
      maximum: `${values?.maxPrice}` || null, // Default to null if not provided
      yearBuiltMin:
        values?.yearBuiltMin === "any" ? null : +values?.yearBuiltMin,
      yearBuiltMax:
        values?.yearBuiltMax === "any" ? null : +values?.yearBuiltMax,
      lotSizeMin: values?.lotSizeMin === "any" ? null : +values?.lotSizeMin,
      lotSizeMax: values?.lotSizeMax === "any" ? null : +values?.lotSizeMax,
      storiesMin: values?.storiesMin === "any" ? null : +values?.storiesMin,
      storiesMax: values?.storiesMax === "any" ? null : +values?.storiesMax,
      squareFeetMin:
        values?.squareFeetMin === "any" ? null : +values?.squareFeetMin,
      squareFeetMax:
        values?.squareFeetMax === "any" ? null : +values?.squareFeetMax,
      garageSpacesMin:
        values?.garageSpacesMin === "any" ? null : +values?.garageSpacesMin,
      garageSpacesMax:
        values?.garageSpacesMax === "any" ? null : +values?.garageSpacesMax,
      bedrooms: values?.bedrooms === "any" ? null : values?.bedrooms,
      bathrooms: values?.bathrooms === "any" ? null : values?.bathrooms,
      ...(selectedTab === 1
        ? {
            type: searchValue?.type,
            search: searchValue?.search,
          }
        : {}),
      // Checkbox filters
      homeType: selectedHomeTypes,
      propertyFeaturesFilter: selectedPropertyFeatures,
      communityFeaturesFilter: selectedCommunityFeatures,
      exteriorFeaturesFilter: selectedExteriorFeatures,
      interiorFeaturesFilter: selectedInteriorFeatures,
      styleFilter: selectedStyle,
      viewFilter: selectedView,
      waterFrontFilter: selectedWaterFront,
      financingFilter: selectedFinancing,
      // E-Alert
      sentBy: values?.sentBy,
      frequency: values?.frequency,
      subject: values?.subject,
      emailsToSend: values?.emailsToSend,
      ...(selectedTab === 0 ? { polygon: polygonValues } : {}),
    };
    return payload;
  }

  // Memoize the filtered formik values to avoid unnecessary re-renders
  const filteredFormikValues = useMemo(() => {
    const { sentBy, frequency, subject, emailsToSend, ...rest } =
      formik?.values || {};
    return rest; // Return only the values excluding the specified ones
  }, [formik?.values]);

  useEffect(() => {
    const {
      leadId,
      sentBy,
      frequency,
      subject,
      emailsToSend,
      ...filterObject
    } = createPayload(formik?.values || {});
    const cleanData = cleanObject(filterObject);

    const isValidPriceRange =
      +formik?.values?.maxPrice &&
      +formik?.values?.minPrice < +formik?.values?.maxPrice;

    if (isValidPriceRange || !+formik?.values?.maxPrice) {
      fetchData(toQueryParams(cleanData));
    }
  }, [
    JSON.stringify(filteredFormikValues),
    searchValue,
    JSON.stringify(polygonValues),
  ]); // Add JSON.stringify for complex objects

  function navigateToPropertySearch() {
    const {
      leadId,
      sentBy,
      frequency,
      subject,
      emailsToSend,
      ...filterObject
    } = createPayload(formik?.values);
    const cleanData = cleanObject(filterObject);
    const urlData = toQueryParams(cleanData);
    const url = `${process.env.REACT_APP_PROPERTY_LISTING}/buy-property?${urlData}`;
    window?.open(url, "_blank", "noopener,noreferrer");
  }

  // Manage Garage Space Min Value
  useEffect(() => {
    let min = +formik?.values?.garageSpacesMin;
    let max = +formik?.values?.garageSpacesMax;
    let value = "";
    if (min > max) {
      const filerCount = filterCountOptions?.map((item) => item?.value);
      let index = filerCount?.indexOf(min?.toString());
      if (index === filerCount?.length - 1) {
        value = "any";
      } else {
        value = filerCount?.[index + 1];
      }
      formik?.setFieldValue("garageSpacesMax", value);
    }
  }, [formik?.values?.garageSpacesMin]);

  // Manage Garage Space Max Value
  useEffect(() => {
    let min = +formik?.values?.garageSpacesMin;
    let max = +formik?.values?.garageSpacesMax;
    let value = "";
    if (min > max) {
      const filerCount = filterCountOptions?.map((item) => item?.value);
      let index = filerCount?.indexOf(max?.toString());
      if (index === 0) {
        value = "any";
      } else {
        value = filerCount?.[index - 1];
      }
      formik?.setFieldValue("garageSpacesMin", value);
    }
  }, [formik?.values?.garageSpacesMax]);

  // Manage Stories Min Value
  useEffect(() => {
    let min = +formik?.values?.storiesMin;
    let max = +formik?.values?.storiesMax;
    let value = "";
    if (min > max) {
      const filerCount = filterCountOptions?.map((item) => item?.value);
      let index = filerCount?.indexOf(min?.toString());
      if (index === filerCount?.length - 1) {
        value = "any";
      } else {
        value = filerCount?.[index + 1];
      }
      formik?.setFieldValue("storiesMax", value);
    }
  }, [formik?.values?.storiesMin]);

  // Manage Stories Max Value
  useEffect(() => {
    let min = +formik?.values?.storiesMin;
    let max = +formik?.values?.storiesMax;
    let value = "";
    if (min > max) {
      const filerCount = filterCountOptions?.map((item) => item?.value);
      let index = filerCount?.indexOf(max?.toString());
      if (index === filerCount?.length - 1) {
        value = "any";
      } else {
        value = filerCount?.[index + 1];
      }
      formik?.setFieldValue("storiesMin", value);
    }
  }, [formik?.values?.storiesMax]);

  // Manage Year Built Min Value
  useEffect(() => {
    let min = +formik?.values?.yearBuiltMin;
    let max = +formik?.values?.yearBuiltMax;
    let value = "";
    if (min > max) {
      const yearBuilt = yearBuiltOptions?.map((item) => item?.value);
      let index = yearBuilt?.indexOf(min?.toString());
      if (index === 0) {
        value = "any";
      } else {
        value = yearBuilt?.[index - 1];
      }
      formik?.setFieldValue("yearBuiltMax", value);
    }
  }, [formik?.values?.yearBuiltMin]);

  // Manage Year Built Max Value
  useEffect(() => {
    let min = +formik?.values?.yearBuiltMin;
    let max = +formik?.values?.yearBuiltMax;
    let value = "";
    if (min > max) {
      const yearBuilt = yearBuiltOptions?.map((item) => item?.value);
      let index = yearBuilt?.indexOf(max?.toString());
      if (index === yearBuilt?.length - 1) {
        value = "any";
      } else {
        value = yearBuilt?.[index + 1];
      }
      formik?.setFieldValue("yearBuiltMin", value);
    }
  }, [formik?.values?.yearBuiltMax]);

  // Manage Square Feet Min Value
  useEffect(() => {
    let min = +formik?.values?.squareFeetMin;
    let max = +formik?.values?.squareFeetMax;
    let value = "";
    if (min > max) {
      const squareFeet = squareFeetOptions?.map((item) => item?.value);
      let index = squareFeet?.indexOf(min?.toString());
      if (index === squareFeet?.length - 1) {
        value = "any";
      } else {
        value = squareFeet?.[index + 1];
      }
      formik?.setFieldValue("squareFeetMax", value);
    }
  }, [formik?.values?.squareFeetMin]);

  // Manage Square Feet Max Value
  useEffect(() => {
    let min = +formik?.values?.squareFeetMin;
    let max = +formik?.values?.squareFeetMax;
    let value = "";
    if (min > max) {
      const squareFeet = squareFeetOptions?.map((item) => item?.value);
      let index = squareFeet?.indexOf(max?.toString());
      if (index === 0) {
        value = "any";
      } else {
        value = squareFeet?.[index - 1];
      }
      formik?.setFieldValue("squareFeetMin", value);
    }
  }, [formik?.values?.squareFeetMax]);

  // Manage Square Feet Min Value
  useEffect(() => {
    let min = +formik?.values?.lotSizeMin;
    let max = +formik?.values?.lotSizeMax;
    let value = "";
    if (min > max) {
      const lotSize = lotSizeOptions?.map((item) => item?.value);
      let index = lotSize?.indexOf(min?.toString());
      if (index === lotSize?.length - 1) {
        value = "any";
      } else {
        value = lotSize?.[index + 1];
      }
      formik?.setFieldValue("lotsizeMax", value?.toString());
    }
  }, [formik?.values?.lotSizeMin]);

  // Manage Square Feet Max Value
  useEffect(() => {
    let min = +formik?.values?.lotSizeMin;
    let max = +formik?.values?.lotSizeMax;
    let value = "";
    if (min > max) {
      const lotSize = lotSizeOptions?.map((item) => item?.value);
      let index = lotSize?.indexOf(max?.toString());
      if (index === 0) {
        value = "any";
      } else {
        value = lotSize?.[index - 1];
      }
      formik?.setFieldValue("lotSizeMin", value?.toString());
    }
  }, [formik?.values?.lotsizeMax]);

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-[1111]" onClose={() => {}}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>
          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-5xl border-b-2 py-2 transform overflow-visible rounded-2xl bg-white text-left align-middle shadow-xl transition-all p-5">
                  <div className="mt-1 border-b-2 py-2 px-2 sm:mt-0 flex justify-between items-center">
                    <div className="sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="text-lg font-medium leading-6 text-gray-800 flex justify-between items-center"
                      >
                        Property Search
                      </Dialog.Title>
                    </div>
                    <button
                      type="button"
                      className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-0 "
                      onClick={() => {
                        handleModalClose();
                      }}
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <div className="mt-5 overflow-visible">
                    <div className="flex justify-end space-x-2 mr-3">
                      <span className="text-red-500">
                        {propertyCount} Properties Found{" "}
                      </span>
                      {propertyCount > 0 && (
                        <span
                          className="text-blue-500 hover:underline hover:cursor-pointer"
                          onClick={navigateToPropertySearch}
                        >
                          (View)
                        </span>
                      )}
                    </div>
                    <Tab.Group
                      selectedIndex={selectedTab}
                      onChange={setSelectedTab}
                    >
                      <Tab.List className="flex h-auto xs2:flex-nowrap flex-wrap xs2:space-y-0 xs2:mt-0 mt-1 mb-4">
                        <div className="bg-white w-0.5 h-[24px] xxs:inline-block hidden mr-1"></div>
                        <Tab
                          className={({ selected }) =>
                            `cursor-pointer px-5 py-0.5 focus:outline-none focus:ring-0 border ${
                              selected
                                ? " bg-orange-400 text-white"
                                : " hover:bg-orange-400 hover:text-white "
                            }`
                          }
                        >
                          Polygonal Map Tool
                        </Tab>
                        <Tab
                          className={({ selected }) =>
                            `cursor-pointer px-5 py-0.5 focus:outline-none focus:ring-0 border ${
                              selected
                                ? " bg-orange-400 text-white "
                                : " hover:bg-orange-400 hover:text-white "
                            }`
                          }
                          onClick={() => setIsRefetchPropertySearch(true)}
                        >
                          Location Tags
                        </Tab>
                      </Tab.List>
                      <Tab.Panels>
                        <Tab.Panel>
                          {/* Map */}
                          <MapComponent
                            setPolygoneValues={setPolygoneValues}
                            polygonValues={polygonValues}
                            isEditPropertyData={isEditPropertyData}
                            editPropertyData={editPropertyData}
                          />
                        </Tab.Panel>
                        <Tab.Panel>
                          <label className="font-semibold block mb-1 text-gray-700">
                            Location
                          </label>
                          {/* Property Search */}
                          <PropertySearchBar
                            setSearchValue={setSearchValue}
                            searchValue={searchValue}
                          />
                        </Tab.Panel>
                      </Tab.Panels>
                    </Tab.Group>

                    <div className="border-b-2 mt-3 mb-1 xs2:flex xs2:items-start" />
                    <AddPropertyFilter
                      formik={formik}
                      lead={lead}
                      isEditPropertyData={isEditPropertyData}
                      editPropertyData={editPropertyData}
                      domainOptions={domainOptions}
                    />
                    <div className="mt-3 flex flex-row-reverse sm:flex-nowrap flex-wrap space-y-2 sm:space-y-0">
                      <SpinnerButton
                        className="inline-flex w-full justify-center rounded btn-orange  px-3 py-2 text-sm font-semibold text-white shadow-sm sm:ml-3 sm:w-auto"
                        title={"Save & Send Mail"}
                        action={() => setSaveAndSendMailAPICall(true)}
                        type={"submit"}
                        label="Save & Send Mail"
                        loading={saveAndSendMailAPICall}
                      />
                      <SpinnerButton
                        className="inline-flex w-full justify-center rounded btn-orange  px-3 py-2 text-sm font-semibold text-white shadow-sm sm:ml-3 sm:w-auto"
                        title={"Save"}
                        action={() => formik.handleSubmit()}
                        type={"submit"}
                        label="Save"
                        loading={!saveAndSendMailAPICall && isLoading}
                      />
                      <button
                        type="button"
                        className=" w-full justify-center rounded bg-gray px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm bg-gray-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 sm:ml-3 sm:w-auto"
                        onClick={() => {
                          handleModalClose();
                        }}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
