import { serverApi } from "Redux/serverApi";
import { gqlBody } from "./gqlBody";

// Create a userAuth object using serverApi.injectEndpoints
const Tag = serverApi.injectEndpoints({
  endpoints: (builder) => ({
    listTag: builder.query({
      query: () => {
        return {
          method: "POST",
          body: gqlBody.ListTag(),
        };
      },
      providesTags: ["TagList"],
    }),
    addTag: builder.mutation({
      query: (payload) => {
        return {
          method: "POST",
          body: gqlBody.AddTag(payload),
        };
      },
      invalidatesTags: ["LeadList", "TagList", "userList"],
    }),
    getTag: builder.mutation({
      query: (payload) => {
        return {
          method: "POST",
          body: gqlBody.getTag(payload),
        };
      },
    }),
    editTag: builder.mutation({
      query: (payload) => {
        return {
          method: "POST",
          body: gqlBody.editTag(payload),
        };
      },
      invalidatesTags: ["LeadList", "TagList"],
    }),
    deleteTag: builder.mutation({
      query: (payload) => {
        return {
          method: "POST",
          body: gqlBody.deleteTag(payload),
        };
      },
      invalidatesTags: ["LeadList", "TagList"],
    }),
  }),
  // Do not override existing endpoints
  overrideExisting: false,
});

export const {
  useListTagQuery,
  useAddTagMutation,
  useGetTagMutation,
  useEditTagMutation,
  useDeleteTagMutation,
} = Tag;
