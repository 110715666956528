import {
  ChatBubbleLeftEllipsisIcon,
  ChatBubbleLeftIcon,
  ChatBubbleLeftRightIcon,
  EnvelopeIcon,
  EnvelopeOpenIcon,
  MicrophoneIcon,
  NoSymbolIcon,
  PhoneArrowUpRightIcon,
  PhoneIcon,
  PhoneXMarkIcon,
  XMarkIcon,
} from "@heroicons/react/24/solid";
import {
  CallIcon,
  CallWrong,
  CallingIcon,
  callFailed,
  invalidEmailIcon,
  invalidSMSPhoneIcon,
  outlineSMSPhoneIcon,
  solidSMSPhoneIcon,
  validEmailIcon,
} from "Assets/icons/SvgIcons";
import {
  checkAnyFilterApplyed,
  getLocalStorage,
  getTimeZone,
  getUserId,
  isValidArray,
  isValidObject,
  setLocalStorage,
  showErrorMsg,
} from "Modules/util";
import moment from "moment-timezone";
import dripIcon from "Assets/icons/dripIcon.svg";
import {
  ArrowTrendingDownIcon,
  BoltIcon,
  MinusIcon,
} from "@heroicons/react/24/outline";

export function getUserTypeList(getAllUser, roleName) {
  let agents = [];
  let users = getAllUser?.data?.users?.response;
  if (isValidArray(users)) {
    agents = users?.filter((user) => user?.role?.roleName === roleName);
  } else {
    agents = [];
  }
  return agents?.map((agent) => {
    return {
      label: agent?.name,
      value: agent?._id,
    };
  });
}

export function getCategoryList(getCategory) {
  let categorys = getCategory?.data?.listCategory?.response;
  if (isValidArray(categorys)) {
  } else {
    categorys = [];
  }
  return categorys?.map((category) => {
    return {
      label: category?.title,
      value: category?._id,
    };
  });
}

export function getTagsList(getTags) {
  let tags = getTags?.data?.listTag?.response;
  if (isValidArray(tags)) {
  } else {
    tags = [];
  }
  return tags?.map((category) => {
    return {
      label: category?.title,
      value: category?._id,
    };
  });
}

export function extractValueFromJson(jsonString, key) {
  try {
    const parsedJson = JSON.parse(jsonString);
    if (parsedJson[key]) {
      return parsedJson[key];
    }
  } catch (error) {
    return null;
  }
}

export function hasError(obj) {
  // Check if the object is empty
  if (Object.keys(obj).length === 0) {
    return true; // No errors, return true
  } else {
    // Check if any error message is not empty
    for (const key in obj) {
      if (obj.hasOwnProperty(key) && obj[key]) {
        return false; // There is an error, return false
      }
    }
    return true; // All error messages are empty, return true
  }
}

//  this function only for SMS and Call data show data
export const convertToTimeZone = (dateString) => {
  // Parse the input date string
  const originalMoment = moment.utc(dateString, "ddd, DD MMM YYYY HH:mm:ss ZZ");
  // Convert the moment to the specified time zone
  const convertedMoment = originalMoment.tz(getTimeZone());
  // Format the moment as per the desired format
  const formattedDate = convertedMoment.format("Do MMMM YYYY, h:mm A");

  return formattedDate;
};

export const urlStringToObjcet = (queryString) => {
  const params = new URLSearchParams(queryString);
  const paramsObject = {};
  for (const [key, value] of params) {
    paramsObject[key] = value;
  }
  return paramsObject;
};

export function isUniqueMentionsArray(mentions) {
  const idSet = new Set();
  for (const mention of mentions) {
    if (idSet.has(mention.id)) {
      return false; // Duplicate id found
    }
    idSet.add(mention.id);
  }
  return true; // No duplicate id found
}

export const callStatusOptions = [
  {
    value: "Talked to Prospect",
    label: "Talked to Prospect",
    icon: <ChatBubbleLeftRightIcon className="h-5 w-5 text-cyan-600 mr-1" />,
    customStatus: true,
  },
  {
    value: "Left Message",
    label: "Left Message",
    icon: <ChatBubbleLeftEllipsisIcon className="h-5 w-5 text-lime-600 mr-1" />,
    customStatus: true,
  },
  {
    value: "Call Attempt (no message)",
    label: "Call Attempt (no message)",
    icon: <PhoneArrowUpRightIcon className="h-5 w-5 text-blue-600 mr-1" />,
    customStatus: true,
  },
  {
    value: "Opted Out",
    label: "Opted Out",
    icon: <NoSymbolIcon className="h-5 w-5 text-red-600 mr-1" />,
    customStatus: true,
  },
  {
    value: "Answered",
    label: "Answered",
    icon: <PhoneIcon className="h-5 w-5 text-green-600 mr-1" />,
    customStatus: true,
  },
  {
    value: "Did not answer",
    label: "Did not answer",
    icon: <PhoneXMarkIcon className="h-5 w-5 text-red-600 mr-1" />,
    customStatus: true,
  },
  {
    value: "Wrong Number",
    label: "Wrong Number",
    icon: <XMarkIcon className="h-5 w-5 text-red-600 mr-1" />,
    customStatus: true,
  },
  {
    value: "Left Voicemail",
    label: "Left Voicemail",
    icon: <MicrophoneIcon className="h-5 w-5 text-purple-600" />,
    customStatus: true,
  },
  {
    value: "canceled",
    label: "Canceled",
    icon: <XMarkIcon className="h-5 w-5 text-red-600 mr-1" />,
    customStatus: false,
  },
  {
    value: "completed",
    label: "Completed",
    icon: <PhoneIcon className="h-5 w-5 text-green-600 mr-1" />,
    customStatus: false,
  },
  {
    value: "busy",
    label: "Busy",
    icon: <PhoneXMarkIcon className="h-5 w-5 text-red-600 mr-1" />,
    customStatus: false,
  },
  {
    value: "no-answer",
    label: "No-Answer",
    icon: <PhoneXMarkIcon className="h-5 w-5 text-red-600 mr-1" />,
    customStatus: false,
  },
  {
    value: "failed",
    label: "Failed",
    icon: <div className="h-5 w-5 text-red-600 mr-1">{callFailed}</div>,
    customStatus: true,
  },
];

export const BulkTaskTypeList = [
  { value: "pastdue", label: "Pastdue" },
  { value: "today", label: "Today" },
  { value: "next7Days", label: "Next 7 Days" },
  { value: "allUpcoming", label: "All Upcoming" },
];

export const BulkActionOptions = [
  { value: "Send Mail", label: "Send Mail", permission: "send_email" },
  { value: "Send Message", label: "Send Message", permission: "send_sms" },
  {
    value: "Add To Drip",
    label: "Add To Drip",
    permission: "schedule_drip_leads",
  },
  {
    value: "Export",
    label: "Export",
    permission: "export_lead",
  },
  {
    value: "Change Category",
    label: "Change Category",
    permission: "edit_leads",
  },
  { value: "Add Tag", label: "Add Tag", permission: "edit_leads" },
  { value: "Remove Tag", label: "Remove Tag", permission: "edit_leads" },
  { value: "Complete Task", label: "Complete Task", permission: "edit_task" },
  { value: "Resume Drip", label: "Resume Drip", permission: "edit_drip" },
  { value: "Pause Drip", label: "Pause Drip", permission: "edit_drip" },
  { value: "Assign Agent", label: "Assign Agent", permission: "edit_leads" },
  { value: "Assign Lender", label: "Assign Lender", permission: "edit_leads" },
  { value: "Change Source", label: "Change Source", permission: "edit_leads" },
];

export const todoDateOption = [
  {
    label: "None",
    value: "none",
  },
  {
    label: "All Upcoming",
    value: "allUpcoming",
  },
  {
    label: "Next 7 Days",
    value: "next7Days",
  },
  {
    label: "Today",
    value: "today",
  },
  {
    label: "Last 4 Week",
    value: "last4Week",
  },
  {
    label: "Past Due",
    value: "pastdue",
  },
];

export const dripStatusOption = [
  {
    label: "No Current Drips",
    value: "noCurrentDrips",
  },
  {
    label: "Running",
    value: "running",
  },
  {
    label: "Paused",
    value: "paused",
  },
  {
    label: "Completed",
    value: "completed",
  },
  {
    label: "Terminated",
    value: "terminated",
  },
];

export const communicationStasus = [
  {
    label: "Have Been Communicated With",
    value: "texted",
  },
  {
    label: "Have Not Been Communicated With",
    value: "notTexted",
  },
  {
    label: "Have Replied",
    value: "responded",
  },
  {
    label: "Have Not Replied",
    value: "neverResponded",
  },
];

export const LeadStoryOption = [
  {
    label: "Yes",
    value: "yes",
  },
  {
    label: "No",
    value: "no",
  },
];

export const leadHistoryfilterOption = [
  { label: "All", value: "All" },
  { label: "Task", value: "Task" },
  { label: "Lead", value: "Lead" },
  { label: "Call", value: "Call" },
  { label: "Email", value: "Email" },
  { label: "Note", value: "Note" },
  { label: "Drip", value: "Drip" },
  { label: "SMS", value: "SMS" },
];

export const CheckAppliedFilterValue = (value) => {
  if (value?.length > 1) {
    return "All";
  }
  return value;
};

export const columnObj = {
  firstName: false,
  lastName: false,
  leadType: true,
  email: true,
  phone: true,
  address: true,
  agent: true,
  lender: true,
  tags: true,
  description: true,
  categories: true,
  lastOpenedEmail: true,
  source: true,
  dripsResponse: true,
  todoResponse: true,
  registerDate: true,
  communicationDate: true,
  closeDate: true,
  anniversaryDate: true,
  closingGift: true,
  requestedReview: true,
  leftReview: true,
  socialMediaReview: true,
  lastSmsIncoming: true,
  lastEmailIncoming: true,
  lastCallIncoming: true,
  unresponsiveSMSCount: true,
  notes: true,
};

export const columnLabel = {
  firstName: false,
  lastName: false,
  leadType: "Lead Type",
  email: "Email",
  phone: "Phone",
  address: "Address",
  agent: "Agent",
  lender: "Lender",
  tags: "Tags",
  description: "Description",
  categories: "Categories",
  lastOpenedEmail: "Last Opened Email",
  source: "Source",
  dripsResponse: "Drip",
  todoResponse: "Task",
  registerDate: "Registered Date",
  communicationDate: "Last Action",
  closeDate: "Closed Date",
  anniversaryDate: "Anniversary Date",
  closingGift: "Got Closing Gift",
  requestedReview: "Requested Review",
  leftReview: "Left Review",
  socialMediaReview: "Add To Social Media",
  lastSmsIncoming: "Last SMS Comm.",
  lastEmailIncoming: "Last Email Comm.",
  lastCallIncoming: "Last Call Comm.",
  unresponsiveSMSCount: "Unresponsive Attem.",
  notes: "Notes",
};

export const historyModuleIcon = {
  Drip: (
    <svg
      fill="currentColor"
      width="800px"
      height="800px"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      className={`h-4 w-4 text-orange`}
    >
      <path d="M5.3,11.214A6.791,6.791,0,0,0,10.792,22h2.416A6.791,6.791,0,0,0,18.7,11.214L12,2Zm5.946,8.036a5.24,5.24,0,0,1-4.631-2.779,1,1,0,1,1,1.766-.942,3.239,3.239,0,0,0,2.865,1.721,1,1,0,0,1,0,2Z" />
    </svg>
  ),
  Email: (
    <svg
      width="800"
      height="800"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      className={`h-4 w-4 text-orange`}
    >
      <path
        fill="#FC5404"
        fillRule="evenodd"
        d="m7.172 11.334 2.83 1.935 2.728-1.882 6.115 6.033q-.242.079-.512.08H1.667c-.22 0-.43-.043-.623-.12zM20 6.376v9.457c0 .247-.054.481-.15.692l-5.994-5.914zM0 6.429l6.042 4.132-5.936 5.858A1.7 1.7 0 0 1 0 15.833zM18.333 2.5c.92 0 1.667.746 1.667 1.667v.586L9.998 11.648 0 4.81v-.643C0 3.247.746 2.5 1.667 2.5z"
      />
    </svg>
  ),
  Task: (
    <svg
      width="800"
      height="800"
      viewBox="-0.5 0 21 21"
      fill="#FC5404"
      xmlns="http://www.w3.org/2000/svg"
      className={`h-4 w-4 text-orange`}
    >
      <path
        opacity=".8"
        d="M14.19.97H5.81C2.17.97 0 3.14 0 6.78v8.38c0 3.64 2.17 5.81 5.81 5.81h8.38c3.64 0 5.81-2.17 5.81-5.81V6.78C20 3.14 17.83.97 14.19.97"
        fill="#FC5404"
      />
      <path
        d="M16.31 7.84c0 .41-.33.75-.75.75h-5.25c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h5.25c.42 0 .75.34.75.75m-8.34-.97L5.72 9.12c-.15.15-.34.22-.53.22s-.39-.07-.53-.22l-.75-.75c-.3-.29-.3-.77 0-1.06.29-.29.76-.29 1.06 0l.22.22 1.72-1.72c.29-.29.76-.29 1.06 0 .29.29.29.77 0 1.06m8.34 7.97c0 .41-.33.75-.75.75h-5.25c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h5.25c.42 0 .75.34.75.75m-8.34-.97-2.25 2.25c-.15.15-.34.22-.53.22s-.39-.07-.53-.22l-.75-.75c-.3-.29-.3-.77 0-1.06.29-.29.76-.29 1.06 0l.22.22 1.72-1.72c.29-.29.76-.29 1.06 0 .29.29.29.77 0 1.06"
        fill="#ffffff"
      />
    </svg>
  ),
  SMS: (
    <svg
      width="800"
      height="800"
      viewBox="0 0 48 48"
      version="1"
      xmlns="http://www.w3.org/2000/svg"
      className={`h-4 w-4 text-orange`}
    >
      <path
        fill="#f97316"
        d="M37 39H11l-6 6V11c0-3.3 2.7-6 6-6h26c3.3 0 6 2.7 6 6v22c0 3.3-2.7 6-6 6"
      />
      <g fill="#fff">
        <circle cx="24" cy="22" r="3" />
        <circle cx="34" cy="22" r="3" />
        <circle cx="14" cy="22" r="3" />
      </g>
    </svg>
  ),
  Note: (
    <svg
      width="800"
      height="800"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`h-4 w-4 text-orange`}
    >
      <path
        d="M20 8.25V18c0 3-1.79 4-4 4H8c-2.21 0-4-1-4-4V8.25c0-3.25 1.79-4 4-4 0 .62.25 1.18.66 1.59s.97.66 1.59.66h3.5C14.99 6.5 16 5.49 16 4.25c2.21 0 4 .75 4 4"
        fill="#f97316"
      />
      <path
        d="M16 4.25c0 1.24-1.01 2.25-2.25 2.25h-3.5c-.62 0-1.18-.25-1.59-.66S8 4.87 8 4.25C8 3.01 9.01 2 10.25 2h3.5c.62 0 1.18.25 1.59.66s.66.97.66 1.59"
        fill="#000"
      />
      <path
        d="M12 13.75H8c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h4c.41 0 .75.34.75.75s-.34.75-.75.75m4 4H8c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h8c.41 0 .75.34.75.75s-.34.75-.75.75"
        fill="#ffff"
      />
    </svg>
  ),
  Call: (
    <svg
      height="800"
      width="800"
      fill="#f97316"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      className={`h-4 w-4 text-orange`}
    >
      <path d="m426.7 453.8-38.1-79.1c-8.2-16.9-18.8-29.2-37.1-21.7l-36.1 13.4c-28.9 13.4-43.3 0-57.8-20.2l-65-147.9c-8.2-16.9-3.9-32.8 14.4-40.3l50.5-20.2c18.3-7.6 15.4-23.4 7.2-40.3l-43.3-80.6c-8.2-16.9-25-21-43.3-13.5-36.6 15.1-66.9 38.8-86.6 73.9-24 42.9-12 102.6-7.2 127.7s21.6 69.1 43.3 114.2c21.7 45.2 40.7 80.7 57.8 100.8 17 20.1 57.8 75.1 108.3 87.4 41.4 10 86.1 1.6 122.7-13.5 18.4-7.2 18.4-23.1 10.3-40.1" />
    </svg>
  ),
  Lead: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="800"
      height="800"
      fill="#FC5404"
      viewBox="0 0 100 100"
      className={`h-5 w-5 text-orange`}
    >
      <path d="M37.1 62.3H77c1.1 0 2 .9 2 2v4c0 1.1-.9 2-2 2H37.1c-1.1 0-2-.9-2-2v-4c0-1.1.9-2 2-2m-12.1 8h-4c-1.1 0-2-.9-2-2v-4c0-1.1.9-2 2-2h4c1.1 0 2 .9 2 2v4c.1 1-.7 1.9-1.8 2zm0-17.9h-4c-1.1 0-2-.9-2-2v-4c0-1.1.9-2 2-2h4c1.1 0 2 .9 2 2v4c.1 1-.7 1.9-1.8 2zm0-17.9h-4c-1.1 0-2-.9-2-2v-4c0-1.1.9-2 2-2h4c1.1 0 2 .9 2 2v4c.1 1-.7 1.9-1.8 2zm12.1 9.9h35.1c1.1 0 2 .9 2 2v4c0 1.1-.9 2-2 2H37.1c-1.1 0-2-.9-2-2v-4c0-1.1.9-2 2-2m0-17.8H77c1.1 0 2 .9 2 2v4c0 1.1-.9 2-2 2H37.1c-1.1 0-2-.9-2-2v-4c0-1.1.9-2 2-2" />
    </svg>
  ),
};
// Function to manage visibility of certain columns based on whether they are closed or not
export const manageIsClosed = async (
  isClosedAppyed,
  visibleColumn = {},
  addFilter = () => {}
) => {
  if (getLocalStorage("closed") === JSON.stringify(isClosedAppyed)) {
    return;
  }
  setLocalStorage("closed", isClosedAppyed); // Update visibility of closed-related columns
  if (!isClosedAppyed) {
    return true;
  }
  // Define columns with default visibility settings
  let colums = {
    ...visibleColumn,
    closeDate: false,
    anniversaryDate: false,
    closingGift: false,
    requestedReview: false,
    leftReview: false,
    socialMediaReview: false,
  };

  let updatedColumns = []; // Check if colums object is valid
  if (isValidObject(colums)) {
    // Iterate through columns to find hidden ones
    for (const key in colums) {
      if (colums?.hasOwnProperty(key) && colums[key] === false) {
        updatedColumns?.push(key); // Push hidden columns to updatedColumns array
      }
    }
    // Prepare payload for API call to update filter
    const filterPayload = {
      userId: getUserId(),
      columns: updatedColumns,
    };
    try {
      // Call addFilter API endpoint to update filter settings
      const res = await addFilter(filterPayload);
      const filterData = res?.data?.data?.addFilter;
      if (isValidObject(filterData)) {
        const { type, message } = filterData;
        if (type === "error") {
          showErrorMsg(message); // Show error message if filter addition fails
        }
      }
    } catch (error) {
      showErrorMsg("Error occurred while Filter Column."); // Show error message on API call failure
    }
  }
};

export const phoneOptOutOptions = [
  {
    value: "Unknown",
    label: "Unknown",
    icon: PhoneIcon,
    iconColor: "text-gray-600",
  },
  {
    value: "Valid",
    label: "Valid",
    icon: PhoneIcon,
    iconColor: "text-green-600",
  },
  {
    value: "Do Not Call",
    label: "Do Not Call",
    icon: PhoneXMarkIcon,
    iconColor: "text-red-600",
  },
  {
    value: "Wrong Number",
    label: "Wrong Number",
    icon: PhoneXMarkIcon,
    iconColor: "text-red-600",
  },
];

export const SmsOptOutOptions = [
  {
    value: "Can Be Texted",
    label: "Can Be Texted",
    icon: ChatBubbleLeftEllipsisIcon,
    iconColor: "text-green-600",
  },
  {
    value: "Cannot Be Texted",
    label: "Cannot Be Texted",
    icon: ChatBubbleLeftIcon,
    iconColor: "text-red-600",
  },
];
export const EmailOptOutOptions = [
  {
    value: "Unknown",
    label: "Unknown",
    icon: EnvelopeIcon,
    iconColor: "text-gray-600",
  },
  {
    value: "InValid",
    label: "Invalid",
    icon: EnvelopeIcon,
    iconColor: "text-red-600",
  },
  {
    value: "Valid",
    label: "Valid",
    icon: EnvelopeOpenIcon,
    iconColor: "text-green-600",
  },
  {
    value: "Opted-Out",
    label: "Opted-Out",
    icon: EnvelopeIcon,
    iconColor: "text-red-600",
  },
];

export const defaultSegmentOrdering = [
  {
    container: 1,
    name: "CallLogsHistory",
  },
  {
    container: 2,
    name: "SMS",
  },
  {
    container: 3,
    name: "EmailHistory",
  },
  {
    container: 4,
    name: "Tasks",
  },
  {
    container: 5,
    name: "PropertySearches",
  },
  {
    container: 6,
    name: "Drip",
  },
  {
    container: 7,
    name: "Note",
  },
  {
    container: 8,
    name: "History",
  },
];

export const OptedOutTypeOptions = [
  {
    label: "None",
    value: "none",
  },
  {
    label: "Manually",
    value: "manually",
  },
  {
    label: "Stop",
    value: "stop",
  },
];
// Function to handle the call button's appearance based on phone statuses
export const callButton = (phones, callReceiveCount) => {
  if (!isValidArray(phones)) return { color: "text-red-600", icon: CallWrong };
  const callStatusArray = phones?.map((phone) =>
    phone?.callStatus?.toLowerCase()
  );
  const uniqueCallStatus = new Set(callStatusArray);

  if (uniqueCallStatus?.size === 1) {
    const callStatus = uniqueCallStatus?.values()?.next()?.value;
    if (callStatus === "valid" && callReceiveCount > 0)
      return { color: "!text-green-600", icon: CallingIcon };
    if (callStatus === "unknown")
      return { color: "!text-gray-600", icon: CallIcon };
    if (callStatus === "do not call" || callStatus === "wrong number")
      return { color: "text-red-600", icon: CallWrong };
  }
  return { color: "!text-gray-600", icon: CallIcon };
};

// Function to handle the SMS button's appearance based on SMS statuses
export const smsButton = (sms, smsReceiveCount) => {
  if (!isValidArray(sms)) {
    return { color: "text-red-600", icon: invalidSMSPhoneIcon }; // Icon for invalid case
  }
  const smsStatusArray = sms?.map((phone) => phone?.smsStatus?.toLowerCase());
  const uniqueSmsStatus = new Set(smsStatusArray);
  if (uniqueSmsStatus?.size === 1) {
    const smsStatus = uniqueSmsStatus?.values()?.next()?.value;
    if (smsStatus === "can be texted" && smsReceiveCount > 0) {
      return { color: "!text-green-600", icon: solidSMSPhoneIcon }; // Icon for "Can Be Texted"
    }
    if (smsStatus === "cannot be texted") {
      return { color: "text-red-600", icon: invalidSMSPhoneIcon }; // Icon for "Cannot Be Texted"
    }
  }
  return { color: "!text-gray-600", icon: outlineSMSPhoneIcon }; // Icon for mixed statuses
};

// Function to handle the email button's appearance based on email statuses
export const emailButton = (emails, emailReceiveCount) => {
  if (!isValidArray(emails)) {
    return { color: "text-red-600", icon: invalidEmailIcon }; // Icon for invalid case
  }

  const emailStatusArray = emails?.map((email) =>
    email?.emailStatus?.toLowerCase()
  );
  const uniqueEmailStatus = new Set(emailStatusArray);

  if (uniqueEmailStatus?.size === 1) {
    const emailStatus = uniqueEmailStatus?.values()?.next()?.value;
    if (emailStatus === "valid" && emailReceiveCount > 0) {
      return { color: "!text-green-600", icon: validEmailIcon }; // Icon for "Valid"
    }
    if (emailStatus === "unknown") {
      return { color: "!text-gray-600", icon: validEmailIcon }; // Icon for "Unknown"
    }
    if (emailStatus === "opted-out" || emailStatus === "invalid") {
      return { color: "text-red-600", icon: invalidEmailIcon }; // Icon for "Opted-Out" or "Invalid"
    }
  }

  return { color: "!text-gray-600", icon: validEmailIcon }; // Icon for mixed statuses
};

export const checkOnlyCloseFilterApplied = (
  filterData,
  updatedSelectedCategories,
  isIncluded
) => {
  if (
    !!isIncluded &&
    updatedSelectedCategories?.length === 1 &&
    !checkAnyFilterApplyed(filterData)
  ) {
    return true;
  }
  if (
    updatedSelectedCategories?.length === 0 &&
    !checkAnyFilterApplyed(filterData) &&
    filterData?.closeEndDate &&
    filterData?.closeStartDate
  ) {
    return true;
  }
  return false;
};

export const anyValueIsTrueInObject = (obj) => {
  const result = Object?.values(obj || {})?.includes(true) ? false : true;
  return result;
};
export const arraysEqual = (a, b) => {
  // Check if lengths are different
  if (a?.length !== b?.length) return false;
  // Sort both arrays
  let sortedA = [...a]?.sort()?.filter((item) => item?.trim());
  let sortedB = [...b]?.sort()?.filter((item) => item?.trim());

  // Check if any corresponding elements are different
  for (let i = 0; i < sortedA?.length; i++) {
    if (sortedA?.[i]?.trim() !== sortedB?.[i]?.trim()) return false;
  }

  return true;
};

export const getEmailFrom = (isIndividualLead, loginUser) => {
  if (isIndividualLead) {
    return loginUser?.individualFromEmail;
  } else {
    return loginUser?.bulkFromEmail;
  }
};

// Home Type
export const propertyCheckboxs = [
  {
    variable: "BusinessOpportunity",
    label: "Business Opportunity",
  },
  {
    variable: "CommercialLease",
    label: "Commercial Lease",
  },
  {
    variable: "CommercialSale",
    label: "Commercial Sale",
  },
  {
    variable: "ManufacturedInPark",
    label: "Manufactured In Park",
  },
  { variable: "Land", label: "Land" },
  { variable: "Residential", label: "Residential" },
  {
    variable: "ResidentialIncome",
    label: "Residential Income",
  },
  {
    variable: "ResidentialLease",
    label: "Residential Lease",
  },
];
// Home Type  Variables Only
export const homeTypes = [
  "BusinessOpportunity",
  "ManufacturedInPark",
  "ResidentialIncome",
  "ResidentialLease",
  "Land",
  "CommercialLease",
  "CommercialSale",
  "Residential",
];

// Property Feature
export const propertyFeatureCheckboxs = [
  {
    variable: "OnGolfCourse",
    label: "On Golf Course",
  },
  {
    variable: "NewConstruction",
    label: "New Construction",
  },
  {
    variable: "AccessibilityFeatures",
    label: "Accessibility Features",
  },
  {
    variable: "GreenVerification",
    label: "Green / LEED",
  },
  {
    variable: "CulDeSac",
    label: "Cul-De-Sac",
  },
  {
    variable: "PublicWater",
    label: "Public Water",
  },
  {
    variable: "Sewer",
    label: "Sewer",
  },
  {
    variable: "CornerLot",
    label: "Corner Lot",
  },
  {
    variable: "PetsAllowed",
    label: "Pets Allowed",
  },
  {
    variable: "LandLease",
    label: "Land - Leased",
  },
  {
    variable: "PavedStreet",
    label: "Paved Street",
  },
  {
    variable: "Propane",
    label: "Propane Utilities",
  },
  {
    variable: "UndergroundUtilities",
    label: "Underground Utilities",
  },
  {
    variable: "Solar",
    label: "Solar Utilities",
  },
  {
    variable: "Gas",
    label: "Gas Utilities",
  },
];

// Property Feature Variables Only
export const propertyFeature = [
  "OnGolfCourse",
  "NewConstruction",
  "AccessibilityFeatures",
  "GreenVerification",
  "CulDeSac",
  "PublicWater",
  "Sewer",
  "CornerLot",
  "PetsAllowed",
  "LandLease",
  "PavedStreet",
  "Propane",
  "UndergroundUtilities",
  "Solar",
  "Gas",
];

// Community Features
export const communityFeaturesCheckboxes = [
  {
    variable: "Pool",
    label: "Community Pool",
  },
  {
    variable: "Golf",
    label: "Golf Community",
  },
  {
    variable: "Gated",
    label: "Gated Community",
  },
  {
    variable: "seniorCommunity",
    label: "55+ Community",
  },
  {
    variable: "Playground",
    label: "Community Playground",
  },
  {
    variable: "FitnessCenter",
    label: "Community Fitness Room",
  },
  {
    variable: "CommunityDock",
    label: "Community Dock",
  },
  {
    variable: "HorseProperty",
    label: "Horse Property",
  },
  {
    variable: "TennisCourts",
    label: "Tennis Community",
  },
];

// Community Features Variables Only
export const communityFeatures = [
  "Pool",
  "Golf",
  "Gated",
  "seniorCommunity",
  "Playground",
  "FitnessCenter",
  "CommunityDock",
  "HorseProperty",
  "TennisCourts",
];

// Exterior Features
export const exteriorFeaturesCheckboxs = [
  {
    variable: "Dock",
    label: "Dock",
  },
  {
    variable: "Carport",
    label: "Carport",
  },
  {
    variable: "InGround",
    label: "Pool - Below Ground",
  },
  {
    variable: "AboveGround",
    label: "Pool - Above Ground",
  },
  {
    variable: "Covered",
    label: "Parking - Covered",
  },
  {
    variable: "OffStreet",
    label: "Parking - Off-Street",
  },
  {
    variable: "AttachedGarage",
    label: "Garage - Attached",
  },
  {
    variable: "DetachedGarage",
    label: "Garage - Detached",
  },
  {
    variable: "Patio",
    label: "Patio",
  },
  {
    variable: "BoatSlip",
    label: "Boat Slip",
  },
  {
    variable: "BrickDriveway",
    label: "Brick",
  },
  {
    variable: "Porch",
    label: "Porch",
  },
  {
    variable: "Underground",
    label: "Parking - Underground",
  },
  {
    variable: "Fiberglass",
    label: "Pool - Fiberglass",
  },
  {
    variable: "Gunite",
    label: "Pool - Gunite",
  },
  {
    variable: "SharedDriveway",
    label: "Has Shared Driveway",
  },
  {
    variable: "WorkshopInGarage",
    label: "Workshop",
  },
  {
    variable: "Deck",
    label: "Deck",
  },
  {
    variable: "Private",
    label: "Has Private Parking",
  },
  {
    variable: "StuccoWall",
    label: "Stucco",
  },
  {
    variable: "GarageSideRearEntry",
    label: "Garage - Side/Rear Entry",
  },
  {
    variable: "Brick",
    label: "Brick",
  },
  {
    variable: "SprinklerSystem",
    label: "Sprinkler System",
  },
  {
    variable: "SpaHotTub",
    label: "Hot Tub",
  },
  {
    variable: "RvParking",
    label: "Parking - RV / Boat",
  },
  {
    variable: "Storage",
    label: "Storage",
  },
];

// Exterior Features Variables Only
export const exteriorFeatures = [
  "Dock",
  "Carport",
  "InGround",
  "AboveGround",
  "Covered",
  "OffStreet",
  "AttachedGarage",
  "DetachedGarage",
  "Patio",
  "BoatSlip",
  "BrickDriveway",
  "Porch",
  "Underground",
  "Fiberglass",
  "Gunite",
  "SharedDriveway",
  "WorkshopInGarage",
  "Deck",
  "Private",
  "StuccoWall",
  "GarageSideRearEntry",
  "Brick",
  "SprinklerSystem",
  "SpaHotTub",
  "RvParking",
  "Storage",
];

// Interior Features
export const interiorFeaturesCheckboxs = [
  {
    variable: "Furnished",
    label: "Furnished",
  },
  {
    variable: "Fireplace",
    label: "Fireplace",
  },
  {
    variable: "Pantry",
    label: "Pantry",
  },
  {
    variable: "Basement",
    label: "Basement",
  },
  {
    variable: "BasementFinished",
    label: "Basement - Finished",
  },
  {
    variable: "BasementUnfinished",
    label: "Basement - Unfinished",
  },
  {
    variable: "BedroomOnMainLevel",
    label: "Main Floor Bedroom",
  },
  {
    variable: "WoodFloor",
    label: "Hardwood Floors",
  },
  {
    variable: "WetBar",
    label: "Wet Bar",
  },
  {
    variable: "WalkInClosets",
    label: "Walk-In Closet",
  },
  {
    variable: "Unfurnished",
    label: "Unfurnished",
  },
  {
    variable: "CentralAir",
    label: "Central Air Conditioning",
  },
  {
    variable: "Electric",
    label: "Heat - Electric",
  },
  {
    variable: "EvaporativeCooling",
    label: "Cooling - Evap",
  },
  {
    variable: "CathedralCeilings",
    label: "Cathedral Ceiling",
  },
  {
    variable: "CarpetFloor",
    label: "Carpet Floors",
  },
  {
    variable: "GasOven",
    label: "Gas Oven",
  },
  {
    variable: "TileFloor",
    label: "Tile Floors",
  },
  {
    variable: "GraniteCounters",
    label: "Granite Counters",
  },
  {
    variable: "Elevator",
    label: "Elevator",
  },
  {
    variable: "NaturalGas",
    label: "Heat - Gas",
  },
  {
    variable: "WineCellar",
    label: "Cellar",
  },
  {
    variable: "WasherOrDryerInUnit",
    label: "Washer / Dryer In Unit",
  },
];

// Interior Features Variables Only
export const interiorFeatures = [
  "Furnished",
  "Fireplace",
  "Pantry",
  "Basement",
  "BasementFinished",
  "BasementUnfinished",
  "BedroomOnMainLevel",
  "WoodFloor",
  "WetBar",
  "WalkInClosets",
  "Unfurnished",
  "CentralAir",
  "Electric",
  "EvaporativeCooling",
  "CathedralCeilings",
  "CarpetFloor",
  "GasOven",
  "TileFloor",
  "GraniteCounters",
  "Elevator",
  "NaturalGas",
  "WineCellar",
  "WasherOrDryerInUnit",
];

// Style
export const houseStyleCheckboxs = [
  {
    variable: "Ranch",
    label: "Ranch / One Story",
  },
  {
    variable: "PatioHome",
    label: "Patio Home",
  },
  {
    variable: "CapeCod",
    label: "Cape",
  },
  {
    variable: "Colonial",
    label: "Colonial",
  },
  {
    variable: "Contemporary",
    label: "Contemporary",
  },
  {
    variable: "Cottage",
    label: "Cottage",
  },
  {
    variable: "Modern",
    label: "Post Modern",
  },
  {
    variable: "Traditional",
    label: "Traditional",
  },
  {
    variable: "Tudor",
    label: "Tudor",
  },
  {
    variable: "Victorian",
    label: "Victorian",
  },
  {
    variable: "Bungalow",
    label: "Bungalow",
  },
  {
    variable: "Mediterranean",
    label: "Mediterranean",
  },
  {
    variable: "Spanish",
    label: "Spanish",
  },
  {
    variable: "Craftsman",
    label: "Craftsman",
  },
  {
    variable: "MidCenturyModern",
    label: "Midcentury",
  },
  {
    variable: "LogHome",
    label: "Log / Log Look",
  },
];

// House Style Variables Only
export const houseStyle = [
  "Ranch",
  "PatioHome",
  "CapeCod",
  "Colonial",
  "Contemporary",
  "Cottage",
  "Modern",
  "Traditional",
  "Tudor",
  "Victorian",
  "Bungalow",
  "Mediterranean",
  "Spanish",
  "Craftsman",
  "MidCenturyModern",
];

// View
export const houseViewCheckboxs = [
  {
    variable: "River",
    label: "River View",
  },
  {
    variable: "Mountains",
    label: "Mountain View",
  },
  {
    variable: "GolfCourse",
    label: "Golf Course View",
  },
  {
    variable: "Harbor",
    label: "Harbor View",
  },
  {
    variable: "Lake",
    label: "Lake View",
  },
  {
    variable: "Ocean",
    label: "Ocean View",
  },
  {
    variable: "PoolView",
    label: "Poolside View",
  },
  {
    variable: "Bay",
    label: "Bay View",
  },
  {
    variable: "TreesWoods",
    label: "Wooded View",
  },
  {
    variable: "CityLights",
    label: "City View",
  },
  {
    variable: "Desert",
    label: "Desert View",
  },
  {
    variable: "Water",
    label: "Water View",
  },
  {
    variable: "Panoramic",
    label: "Panoramic View",
  },
  {
    variable: "CreekStream",
    label: "Creek View",
  },
  {
    variable: "Canal",
    label: "Canal View",
  },
  {
    variable: "Canyon",
    label: "Canyon View",
  },
  {
    variable: "ParkGreenbelt",
    label: "Greenbelt View",
  },
  {
    variable: "BeachView",
    label: "Beach View",
  },
];

// House View Variables Only
export const houseView = [
  "River",
  "Mountains",
  "GolfCourse",
  "Harbor",
  "Lake",
  "Ocean",
  "PoolView",
  "Bay",
  "TreesWoods",
  "CityLights",
  "Desert",
  "Water",
  "Panoramic",
  "CreekStream",
  "Canal",
  "Canyon",
  "ParkGreenbelt",
  "BeachView",
];

// Waterfront Features
export const waterFrontCheckboxs = [
  {
    variable: "LakeFront",
    label: "Lake Front",
  },
  {
    variable: "Beach",
    label: "Beach (On or Near)",
  },
  {
    variable: "RiverFront",
    label: "River Front",
  },
  {
    variable: "Bayfront",
    label: "Bay Front",
  },
  {
    variable: "Creek",
    label: "Creek Front",
  },
  {
    variable: "OceanFront",
    label: "Ocean Front",
  },
  {
    variable: "CanalFront",
    label: "Canal Front",
  },
  {
    variable: "WaterFront",
    label: "Waterfront",
  },
];

// Waterfront Features Variables Only
export const waterFront = [
  "LakeFront",
  "Beach",
  "RiverFront",
  "Bayfront",
  "Creek",
  "OceanFront",
  "CanalFront",
  "WaterFront",
];

// Financing Options
export const financingCheckboxs = [
  {
    variable: "VaLoan",
    label: "VA Loan",
  },
  {
    variable: "Cash",
    label: "Cash",
  },
  {
    variable: "Contract",
    label: "Contract",
  },
  {
    variable: "LeaseOption",
    label: "Lease Option",
  },
  {
    variable: "Trade",
    label: "Trade/Exchange",
  },
  {
    variable: "FHA",
    label: "FHA Loan",
  },
  {
    variable: "UsdaLoan",
    label: "USDA",
  },
  {
    variable: "Conventional",
    label: "Conventional Financing",
  },
];

// Financing Options Variables Only
export const financing = [
  "VaLoan",
  "Cash",
  "Contract",
  "LeaseOption",
  "Trade",
  "FHA",
  "UsdaLoan",
  "Conventional",
];

export const bedTypeOptions = [
  { value: "any", label: "Any" },
  { value: "1", label: "1+" },
  { value: "2", label: "2+" },
  { value: "3", label: "3+" },
  { value: "4", label: "4+" },
  { value: "5", label: "5+" },
];
export const bathTypeOptions = [
  { value: "any", label: "Any" },
  { value: "1", label: "1+" },
  { value: "2", label: "2+" },
  { value: "3", label: "3+" },
  { value: "4", label: "4+" },
  { value: "5", label: "5+" },
];

export const filterCountOptions = [
  { label: "Any", value: "any" },
  { label: "1", value: "1" },
  { label: "2", value: "2" },
  { label: "3", value: "3" },
  { label: "4", value: "4" },
  { label: "5", value: "5" },
];

export const yearBuiltOptions = [
  { label: "Any", value: "any" },
  { label: "2027", value: "2027" },
  { label: "2026", value: "2026" },
  { label: "2025", value: "2025" },
  { label: "2024", value: "2024" },
  { label: "2023", value: "2023" },
  { label: "2022", value: "2022" },
  { label: "2021", value: "2021" },
  { label: "2020", value: "2020" },
  { label: "2019", value: "2019" },
  { label: "2018", value: "2018" },
  { label: "2017", value: "2017" },
  { label: "2016", value: "2016" },
  { label: "2015", value: "2015" },
  { label: "2014", value: "2014" },
  { label: "2013", value: "2013" },
  { label: "2012", value: "2012" },
  { label: "2011", value: "2011" },
  { label: "2010", value: "2010" },
  { label: "2009", value: "2009" },
  { label: "2008", value: "2008" },
  { label: "2007", value: "2007" },
  { label: "2005", value: "2005" },
  { label: "2000", value: "2000" },
  { label: "1995", value: "1995" },
  { label: "1990", value: "1990" },
  { label: "1980", value: "1980" },
  { label: "1970", value: "1970" },
  { label: "1960", value: "1960" },
  { label: "1950", value: "1950" },
  { label: "1940", value: "1940" },
  { label: "1930", value: "1930" },
  { label: "1920", value: "1920" },
  { label: "1900", value: "1900" },
  { label: "1850", value: "1850" },
  { label: "1800", value: "1800" },
  { value: "1750", label: "1750" },
];

export const squareFeetOptions = [
  { label: "Any", value: "any" },
  { label: "500", value: "500" },
  { label: "550", value: "550" },
  { label: "600", value: "600" },
  { label: "650", value: "650" },
  { label: "700", value: "700" },
  { label: "750", value: "750" },
  { label: "800", value: "800" },
  { label: "850", value: "850" },
  { label: "900", value: "900" },
  { label: "950", value: "950" },
  { label: "1000", value: "1000" },
  { label: "1050", value: "1050" },
  { label: "1100", value: "1100" },
  { label: "1150", value: "1150" },
  { label: "1200", value: "1200" },
  { label: "1250", value: "1250" },
  { label: "1300", value: "1300" },
  { label: "1500", value: "1500" },
  { label: "1750", value: "1750" },
  { label: "2000", value: "2000" },
  { label: "2250", value: "2250" },
  { label: "2500", value: "2500" },
  { label: "2750", value: "2750" },
  { label: "3000", value: "3000" },
  { label: "3500", value: "3500" },
  { label: "4000", value: "4000" },
  { label: "5000", value: "5000" },
];

export const lotSizeOptions = [
  { label: "Any", value: "any" },
  { label: "1/4 acres", value: "0.25" },
  { label: "1/2 acres", value: "0.5" },
  { label: "1 acres", value: "1" },
  { label: "2 acres", value: "2" },
  { label: "5 acres", value: "5" },
  { label: "10 acres", value: "10" },
  { label: "20 acres", value: "20" },
  { label: "50 acres", value: "50" },
  { label: "100 acres", value: "100" },
];

export const frequencyOptions = [
  { label: "Instantly", value: "instantly" },
  { label: "Daily", value: "daily" },
  { label: "Weekly", value: "weekly" },
  { label: "Monthly", value: "monthly" },
];

export const unresponsiveCountOptions = [
  { label: "1", value: 1 },
  { label: "2", value: 2 },
  { label: "3", value: 3 },
  { label: "4", value: 4 },
  { label: "5 or more than 5", value: 5 },
];
