import { Tab } from "@headlessui/react";
import { isValidArray } from "Modules/util";
import { useGetEmailHistoryByLeadIdQuery } from "Redux/Leads/EmailHistory/emailHistory";
import { useEffect, useRef, useState } from "react";
import History from "./History";
import PropertyEmailHistory from "./PropertyEmailHistory";
import moveIcon from "Assets/icons/moveIcon.svg";

export default function EmailHistory(props) {
  const { lead, drag, canDrop, isDragging, emailboxContainerRef } = props;
  const [emailHistoryData, setEmailHistoryData] = useState([]);
  const [propertyEmailHistoryData, setPropertyEmailHistoryData] = useState([]);
  const emailContainerRef = useRef(null);
  const leadId = lead?.leadResponse?.[0]?._id;

  // lead wise email history list api
  const {
    data: getEmailHistoryByLeadId,
    isLoading: isEmailHistoryListLoading,
    refetch: emailHistoryListRefetch,
  } = useGetEmailHistoryByLeadIdQuery(
    {
      _id: leadId,
    },
    { skip: leadId ? false : true }
  );

  useEffect(() => {
    let emailHistoryData =
      getEmailHistoryByLeadId?.data?.getEmailHistoryByLeadId?.emailResponse;
    if (!isEmailHistoryListLoading) {
      if (isValidArray(emailHistoryData)) {
        const emailHistory = emailHistoryData?.filter(
          (email) => !email?.isPropertyEmail
        );
        const propertyEmail = emailHistoryData?.filter(
          (email) => email?.isPropertyEmail
        );
        setEmailHistoryData(emailHistory ?? []);
        setPropertyEmailHistoryData(propertyEmail ?? []);
      } else {
        setEmailHistoryData([]);
        setPropertyEmailHistoryData([]);
      }
    }
  }, [getEmailHistoryByLeadId]);

  return (
    <>
      <Tab.Group>
        <div
          className={`bg-teal-400 py-2 sm:py-0 sm:!h-[40px] rounded-t-2xl flex items-center justify-between px-4 ${
            canDrop && "!bg-teal-300 opacity-85"
          }`}
        >
          <Tab.List className="flex flex-wrap sm:flex-nowrap items-center xs2:space-y-0 space-y-1 xs2:mt-0 mt-1">
            <div className="bg-white w-0.5 h-[24px] hidden sm:inline-block mr-1"></div>
            <Tab
              className={({ selected }) =>
                `cursor-pointer px-3 focus:outline-none focus:ring-0 text-sm lg:py-1 ${
                  selected
                    ? "border-0 bg-teal-500 text-white rounded-full"
                    : "text-slate-200 hover:bg-teal-500 hover:text-white hover:rounded-full"
                }`
              }
            >
              Email History
            </Tab>
            <div className="bg-white w-[1px] h-[24px] mx-2 mt-1 hidden sm:inline-block"></div>
            <Tab
              className={({ selected }) =>
                `cursor-pointer px-3 focus:outline-none focus:ring-0 text-sm lg:py-1 ${
                  selected
                    ? "border-0 bg-teal-500 text-white rounded-full"
                    : "text-slate-200 hover:bg-teal-500 hover:text-white hover:rounded-full"
                }`
              }
            >
              Property Email History
            </Tab>
          </Tab.List>
          <div
            className="bg-teal-600 rounded-md flex items-center p-1.5 h-7 w-7 cursor-move"
            ref={drag}
          >
            <img
              alt="edit-icon"
              src={moveIcon}
              className="h-4 w-4 rounded-md filter invert brightness-0"
              title={"Move Segment"}
            />
          </div>
        </div>
        <Tab.Panels>
          <Tab.Panel>
            <History
              canDrop={canDrop}
              emailContainerRef={emailContainerRef}
              isEmailHistoryListLoading={isEmailHistoryListLoading}
              emailHistoryData={emailHistoryData}
            />
          </Tab.Panel>
          <Tab.Panel>
            <PropertyEmailHistory
              canDrop={canDrop}
              emailContainerRef={emailContainerRef}
              isEmailHistoryListLoading={isEmailHistoryListLoading}
              propertyEmailHistoryData={propertyEmailHistoryData}
            />
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </>
  );
}
