import { serverApi } from "Redux/serverApi";
import { gqlBody } from "./gqlBody";

// Create a userAuth object using serverApi.injectEndpoints
const Category = serverApi.injectEndpoints({
  endpoints: (builder) => ({
    listCategory: builder.query({
      query: () => {
        return {
          method: "POST",
          body: gqlBody.ListCategory(),
        };
      },
    }),
    addCategory: builder.mutation({
      query: (payload) => {
        return {
          method: "POST",
          body: gqlBody.AddCategory(payload),
        };
      },
      invalidatesTags: ["LeadList", "CategoryList"],
    }),
    getCategory: builder.mutation({
      query: (payload) => {
        return {
          method: "POST",
          body: gqlBody.GetCategory(payload),
        };
      },
    }),
    editCategory: builder.mutation({
      query: (payload) => {
        return {
          method: "POST",
          body: gqlBody.EditCategory(payload),
        };
      },
      invalidatesTags: ["LeadList", "CategoryList"],
    }),
    deleteCategory: builder.mutation({
      query: (payload) => {
        return {
          method: "POST",
          body: gqlBody.DeleteCategory(payload),
        };
      },
      invalidatesTags: ["LeadList", "CategoryList"],
    }),
    CheckCategoryBeforeDelete: builder.mutation({
      query: (payload) => {
        return {
          method: "POST",
          body: gqlBody.CheckCategoryBeforeDelete(payload),
        };
      },
      invalidatesTags: ["CategoryList"],
    }),
  }),
  // Do not override existing endpoints
  overrideExisting: false,
});

export const {
  useListCategoryQuery,
  useAddCategoryMutation,
  useGetCategoryMutation,
  useEditCategoryMutation,
  useDeleteCategoryMutation,
  useCheckCategoryBeforeDeleteMutation
} = Category;
