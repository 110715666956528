import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useRef } from "react";
import SpinnerButton from "../Fields/SpinnerButton";
import Button from "../Fields/Button";

const ConfirmationModal = (props) => {
  const {
    id,
    lastNodeId,
    isModalOpen,
    setModalOpen,
    handleAction,
    isLoading = false,
    scheduled,
    nodes = false,
    message = "You want to delete this record?",
    isLastNode = null,
    onNodesCustomDelete = () => {},
  } = props;

  const cancelButtonRef = useRef(null);

  function handleTerminateDrip(id, fieldValues) {
    onNodesCustomDelete(id, fieldValues);
    setModalOpen(false);
  }

  return (
    <Transition.Root appear show={isModalOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-[1111]"
        initialFocus={cancelButtonRef}
        onClose={() => setModalOpen(false)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div className="flex justify-between">
                  <p className="text-lg font-bold">
                    {nodes ? "Warning!" : "Are you Sure ? "}
                  </p>
                  <button
                    type="button"
                    className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                    onClick={() => setModalOpen(false)}
                    ref={cancelButtonRef}
                  >
                    <svg
                      aria-hidden="true"
                      className="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </button>
                </div>
                <div className="my-4 ml-3">
                  <div className="flex flex-wrap  -mx-3 mb-3">
                    {nodes
                      ? !isLastNode && lastNodeId === id && scheduled !== 0
                        ? "This drip will be completed for all leads scheduled."
                        : "You can not delete last node"
                      : message}
                  </div>
                </div>
                {!nodes || (!nodes && lastNodeId === id && scheduled === 0) ? (
                  <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                    <>
                      <SpinnerButton
                        className="inline-flex w-full justify-center px-3 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600 sm:col-start-2 btn-orange disabled:cursor-not-allowed"
                        type="button"
                        action={() => {
                          handleAction(id);
                          setModalOpen(false);
                        }}
                        title="Confirm"
                        loading={isLoading}
                        isDisable={isLoading}
                      />

                      <button
                        type="button"
                        className="mt-3 inline-flex w-full justify-center bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                        onClick={() => setModalOpen(false)}
                        ref={cancelButtonRef}
                      >
                        Cancel
                      </button>
                    </>
                  </div>
                ) : isLastNode ? (
                  <div className="flex w-full justify-end pt-2">
                    <Button
                      title={"Ok"}
                      className="w-1/4"
                      action={() => setModalOpen(false)}
                    />
                  </div>
                ) : (
                  <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                    <>
                      <SpinnerButton
                        className="inline-flex w-full justify-center px-3 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600 sm:col-start-2 btn-orange"
                        type="button"
                        action={() => {
                          handleTerminateDrip(id, " ");
                        }}
                        title="Confirm"
                        loading={isLoading}
                        isDisable={isLoading}
                      />

                      <button
                        type="button"
                        className="mt-3 inline-flex w-full justify-center bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                        onClick={() => setModalOpen(false)}
                        ref={cancelButtonRef}
                      >
                        Cancel
                      </button>
                    </>
                  </div>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default ConfirmationModal;
