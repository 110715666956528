import React from "react";

const Checkbox = ({ id, name, label, checked, onChange, disabled }) => {
  return (
    <div className="flex items-center">
      <input
        id={id}
        name={name}
        type="checkbox"
        className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded disabled:opacity-50 disabled:cursor-not-allowed"
        checked={checked}
        onChange={onChange}
        disabled={disabled}
      />
      <label
        className={`ml-2 block text-sm font-normal leading-6 text-gray-900 
          ${disabled ? "opacity-50 cursor-not-allowed" : ""}`}
        htmlFor={id}
        onClick={(e) => e.preventDefault()}
      >
        {label}
      </label>
    </div>
  );
};

export default Checkbox;
