import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { EnvelopeIcon } from "@heroicons/react/24/solid";
import CustomMultiSelect from "Components/Common/Fields/CustomMultiSelect";
import InputField from "Components/Common/Fields/InputField";
import SelectField from "Components/Common/Fields/SelectField";
import Skeleton from "Components/Common/Fields/Skeleton";
import SpinnerButton from "Components/Common/Fields/SpinnerButton";
import useEscapeClose from "Components/Common/Hooks/useEscapeClose";
import ConfirmationModal from "Components/Common/Modals/ConfirmationModal";
import Editor from "Components/Common/TextEditor/Editor";
import {
  getEmailOptions,
  getOptionData,
  getSendItemsOption,
  getUserId,
  hasNonEmptyValuesInObj,
  isValidArray,
  isValidObject,
  removeUndefined,
  showErrorMsg,
  showSuccessMsg,
} from "Modules/util";
import {
  useCheckScheduleLeadCountQuery,
  useDomainListQuery,
  useGetLeadQuery,
  useSendMailMutation,
} from "Redux/Leads/lead";
import {
  useGetEmailTemplatesQuery,
  useLeadEmailPreviewInputMutation,
} from "Redux/emailTemplate/emailTemplateQuery";
import { setIsLoading, setIsOpenRegenrateGmailModal } from "Redux/themeSlice";
import { useCheckUserQuery } from "Redux/user/user";
import { currentUser } from "Redux/userSlice";
import { useFormik } from "formik";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { getEmailFrom } from "../assetData";

export default function SendMailModal({
  isOpen,
  setIsOpen,
  options,
  leadId,
  setLeadId,
  selectedRows,
  isAllChecked,
  deselected,
  filterData,
  handleResetCheckbox,
  isConfirmationModal = false,
  setIsConfirmationModal = () => {},
}) {
  const [sendMail, { isLoading: isSendMailLoading }] = useSendMailMutation();
  const [id, setId] = useState("");
  const [userEmailList, setUserEmailList] = useState([]);
  const [emailTemplateOptions, setEmailTemplateOptions] = useState([]);
  const [showOptedOutValidation, setShowOptedOutValidation] = useState(false);
  const { data: scheduleLeadsCount, isLoading: isCountLoading } =
    useCheckScheduleLeadCountQuery(
      {
        leadIds: id,
        isSelectedAll: isAllChecked,
        isFilter: hasNonEmptyValuesInObj(filterData),
        Filter: removeUndefined(filterData),
      },
      {
        skip: leadId ? true : false || !id,
      }
    ); // query for schedule lead count

  const [selectedEmailForAssign, setSelectedEmailForAssign] = useState(0); // state for selected email for assign
  const agentList = useSelector((s) => s?.option?.agent);
  const isOpenRegenrateGmailModal = useSelector(
    (s) => s?.theme?.isOpenRegenrateGmailModal
  );
  const { data: domainList } = useDomainListQuery();
  const loginUser = useSelector((state) => state?.user?.currentUser); //getting current logged in user
  const domainOptions = [
    ...(loginUser?.type?.toLowerCase() === "admin" ||
    loginUser?.type?.toLowerCase() === "assistant/agent manager"
      ? [{ value: "assignedAgent", label: "Assigned Agent" }]
      : []),
    ...getEmailOptions(domainList, loginUser),
  ];
  const dispatch = useDispatch();
  const { data: emailTemplatesList } = useGetEmailTemplatesQuery({ page: -1 });
  const [signatureData, setSignatureData] = useState("");
  const [getEmailPreview] = useLeadEmailPreviewInputMutation();
  // function for clear lead id when modal is close with ESC key
  const handleClose = () => {
    setLeadId("");
  };
  useEscapeClose(setIsOpen, isConfirmationModal, handleClose); // Custom hook to close a modal when the Escape key is pressed.
  const isGmailTokenPopupShow = localStorage?.getItem("isGmailTokenPopupShow");
  const [isSkipUserApiCall, setIsSkipUserApiCall] = useState(true);

  // getting current user
  const {
    data: userDeatils,
    isLoading: isUserDeatilsLoading,
    refetch: userDetailsRefetch,
  } = useCheckUserQuery(
    { _id: getUserId() },
    {
      skip: isSkipUserApiCall,
    }
  );

  const {
    data: getLead,
    isLoading: isleadLoading,
    refetch,
  } = useGetLeadQuery(
    { _id: leadId },
    {
      skip: !leadId ? true : false,
    }
  );

  useEffect(() => {
    if (!isleadLoading) {
      if (leadId) {
        let emails = getLead?.data?.lead?.response[0]?.leadResponse[0]?.email;
        let name = getLead?.data?.lead?.response[0]?.leadResponse[0]?.firstName;
        if (isValidArray(emails)) {
          setUserEmailList(getSendItemsOption(emails, name));
        } else {
          setUserEmailList([]);
        }
      } else {
        if (isValidArray(options)) {
          setUserEmailList(options);
        } else {
          setUserEmailList([]);
        }
      }
    }
  }, [leadId, isleadLoading, options, getLead]);

  // setting id as if selected All is true then id is set to deselected id array else set to selected rows
  useEffect(() => {
    if (isAllChecked) {
      setId(deselected);
    } else {
      setId(selectedRows);
    }
  }, [selectedRows, isAllChecked]);

  useEffect(() => {
    if (!isUserDeatilsLoading) {
      const loginUser = userDeatils?.data?.checkUserAccount?.response;
      if (isValidObject(loginUser)) {
        if (
          loginUser?.refreshToken &&
          loginUser?.isTokenRevoke &&
          (isGmailTokenPopupShow === "true" || !isGmailTokenPopupShow)
        ) {
          if (
            loginUser?.type?.toLowerCase() !== "admin" &&
            loginUser?.name?.toLowerCase() !== "admin admin"
          ) {
            dispatch(setIsOpenRegenrateGmailModal(true));
          }
        }
        dispatch(currentUser(loginUser));
      }
    }
  }, [userDeatils]);

  const handleSubmit = (values) => {
    if (!leadId) {
      dispatch(
        setIsLoading({
          isLoading: true,
          loadingMessage: "Please wait, the email is being sent...",
        })
      );
    }
    let obj = {};
    let emails = [];
    if (leadId) {
      values?.email?.map((value, index) => (emails[index] = value));
      if (isValidArray(emails)) {
        if (emails?.[0]?.value) {
          emails = emails.map((email) => email?.value);
        }
      }
      obj = { emails: emails, ids: [leadId] };
    } else {
      obj = { ids: [...id] };
    }
    obj = {
      ...obj,
      fromEmail:
        values?.from === "assignedAgent"
          ? "assignedAgent"
          : values?.from?.toLowerCase(),
      appPassword:
        values?.from === loginUser?.email ? loginUser?.appPassword : "",
      replyTo: values?.replyTo,
      fromName:
        values?.fromName === "Assigned Agent Name" ? "" : values?.fromName,
      body: values?.description,
      subject: values?.subject,
      isSelectedAll: isAllChecked,
      isFilter: leadId ? false : hasNonEmptyValuesInObj(filterData),
      Filter: removeUndefined(filterData),
    };

    sendMail(obj)
      .then((response) => {
        if (response?.data?.data?.sendEmails?.type === "success") {
          let count = response?.data?.data?.sendEmails?.count;
          if (!leadId) {
            dispatch(setIsLoading({ isLoading: false, loadingMessage: "" }));
          }
          showSuccessMsg(
            `${count} ${count > 1 ? "Emails" : "Email"} sent successfully.`
          );
          handleResetCheckbox && handleResetCheckbox();
          formik.resetForm();
          setIsOpen(false);
        } else if (response?.data?.data?.sendEmails?.type === "error") {
          showErrorMsg(response?.data?.data?.sendEmails?.message);
          setIsSkipUserApiCall(false);
          if (!leadId) {
            dispatch(setIsLoading({ isLoading: false, loadingMessage: "" }));
          }
        } else {
          if (!leadId) {
            dispatch(setIsLoading({ isLoading: false, loadingMessage: "" }));
          }
          setIsOpen(false);
          showErrorMsg("Error occurred while Sending Mail.");
        }
      })
      .catch((error) => {
        if (!leadId) {
          dispatch(setIsLoading({ isLoading: false, loadingMessage: "" }));
        }
        setIsSkipUserApiCall(false);
        showErrorMsg("Error occurred while Sending Mail.");
      });
  };
  const getUserEmailInitialValue = (list) => {
    if (isValidArray(list)) {
      if (list?.[0]?.value) {
        return userEmailList?.map((item) => item.value);
      } else {
        return list;
      }
    } else {
      return [];
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      replyTo: null,
      email: getUserEmailInitialValue(userEmailList),
      subject: "",
      description: leadId
        ? "<p> </p><p>{{emailSignature}}</p>"
        : '<p> </p><p>{{emailSignature}}</p><p>If you no longer wish to receive these emails, you can <a href="{{unsubscribeLink}}" target="_blank">unsubscribe here</a></p>',
      from: getEmailFrom(leadId, loginUser) ?? null,
      fromName:
        getEmailFrom(leadId, loginUser) === "assignedAgent" && !leadId
          ? "Assigned Agent Name"
          : loginUser?.firstName,
      template: "template",
    },
    validationSchema: Yup.object().shape({
      email:
        leadId &&
        Yup.array().min(1, "Email Is Required").required("Email Is Required"),
      subject: Yup.string().required("Subject Is Required"),
      from: Yup.string().required("From is required"),
      description: Yup.string()
        .notOneOf(["", "<p><br></p>", " "], "Email body is required")
        .test("is-not-empty", "Email body is required", (value) => {
          // Check if the value is null or only spaces
          const doc = new DOMParser().parseFromString(
            value?.trim(),
            "text/html"
          );
          return doc?.body?.textContent?.trim()?.length > 0;
        })
        .required("Email body is required"),
    }),
    onSubmit: async (values) => {
      const isBulk = leadId ? false : true;
      if (isBulk === true) {
        setIsConfirmationModal(true);
      } else {
        handleSubmit(values);
      }
    },
  });
  const handlePreview = async () => {
    // for individual email preview
    if (leadId) {
      const response = await getEmailPreview({
        body: formik?.values?.description,
        fromEmail: formik?.values?.from,
        leadId: leadId,
      });
      if (response?.data?.data?.leadEmailPreview?.body) {
        setSignatureData(response?.data?.data?.leadEmailPreview?.body);
        return;
      } else {
        setSignatureData("");
      }
    }
  };

  function checkIfValueInclude(email, optedOutEmail) {
    for (let i = 0; i < optedOutEmail?.length; i++) {
      if (email?.includes(optedOutEmail[i])) {
        return true;
      }
    }
    return false;
  }

  useEffect(() => {
    if (isOpen) {
      if (leadId) {
        refetch();
      }
    }
  }, [isOpen]);

  useEffect(() => {
    let lead = getLead?.data?.lead?.response?.[0];
    if (isValidArray(lead?.leadResponse)) {
      let optedOutEmail = lead?.leadResponse[0]?.emailStatusDetail
        ?.filter(
          (emailInfo) =>
            emailInfo?.emailStatus === "Opted-Out" ||
            emailInfo?.emailStatus === "InValid"
        )
        .map((emailInfo) => emailInfo?.email);
      setShowOptedOutValidation(
        checkIfValueInclude(formik?.values?.email, optedOutEmail)
      );
    }
  }, [formik?.values?.email]);

  useEffect(() => {
    if (!leadId) {
      if (scheduleLeadsCount?.data?.checkLeadCount === null) {
        showErrorMsg("Error occurred while fetching lead count");
      } else {
        if (scheduleLeadsCount?.data?.checkLeadCount?.leadCount) {
          setSelectedEmailForAssign(
            scheduleLeadsCount?.data?.checkLeadCount?.leadCount
          );
        }
      }
    }
  }, [scheduleLeadsCount, leadId]);

  useEffect(() => {
    const emailList = emailTemplatesList?.data?.emailTemplates?.templates;
    setEmailTemplateOptions([
      {
        label: "Select Template",
        value: "template",
      },
      ...getOptionData(emailList),
    ]);
  }, [emailTemplatesList]);

  useEffect(() => {
    if (formik?.values?.template !== "template") {
      const selectedTemplate =
        emailTemplatesList?.data?.emailTemplates?.templates?.find(
          (t) => t?._id === formik?.values?.template
        );
      if (isValidObject(selectedTemplate)) {
        const signature = leadId
          ? "<p>{{emailSignature}}</p>"
          : '<p>{{emailSignature}}</p><p>If you no longer wish to receive these emails, you can <a href="{{unsubscribeLink}}" target="_blank">unsubscribe here</a></p>';
        formik.setFieldValue("description", selectedTemplate?.body + signature);
        formik.setFieldValue("subject", selectedTemplate?.subject);
      }
    } else {
      formik.setFieldValue("description", "");
      formik.setFieldValue("subject", "");
    }
  }, [formik?.values?.template]);

  useEffect(() => {
    if (!leadId) {
      if (formik?.values?.from === "assignedAgent") {
        formik.setFieldValue("fromName", "Assigned Agent Name");
      } else {
        formik.setFieldValue("fromName", loginUser?.name);
      }
    }
  }, [formik?.values?.from, leadId]);

  useEffect(() => {
    if (isOpenRegenrateGmailModal && isOpen) {
      setIsOpen(false);
    }
  }, [isOpenRegenrateGmailModal]);

  const handleAction = () => {
    if (isSendMailLoading) return; // Prevent multiple clicks
    handleSubmit(formik?.values);
  };

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-[1111]"
          onClose={() => {
            // setIsOpen(false);
            // formik.resetForm();
            // setLeadId("");
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>

          <ConfirmationModal
            isModalOpen={isConfirmationModal}
            setModalOpen={setIsConfirmationModal}
            handleAction={() => handleAction()}
            isLoading={isSendMailLoading}
            message={`You are about to assign email to ${selectedEmailForAssign} leads, proceed?`}
          />
          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-5xl border-b-2 py-2 transform overflow-auto rounded-2xl bg-white text-left align-middle shadow-xl transition-all p-5">
                  <div className="mt-1 border-b-2 py-2 sm:mt-0 flex justify-between items-center">
                    <div className="sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="text-lg font-medium leading-6 text-gray-800 flex justify-between items-center"
                      >
                        <EnvelopeIcon className="w-5 text-orange-500 mr-2" />
                        Send Mail
                      </Dialog.Title>
                    </div>
                    <button
                      type="button"
                      className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-0 "
                      onClick={() => {
                        setIsOpen(false);
                        formik.resetForm();
                        setLeadId("");
                      }}
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <form
                    className="space-y-2 mt-2 max-h-[900px] overflow-visible p-1"
                    onSubmit={formik.handleSubmit}
                  >
                    {!leadId && (
                      <>
                        <span className="text-sm">
                          <span className="font-medium">To:</span>
                          <span className="ml-2">
                            {isCountLoading ? (
                              <Skeleton
                                className="my-0 mx-1 w-[48px]"
                                counter={1}
                                height={"24px"}
                              />
                            ) : (
                              selectedEmailForAssign
                            )}{" "}
                            Leads
                          </span>
                        </span>
                      </>
                    )}
                    <div className="flex justify-between xs1:space-x-4 space-x-0 flex-col xs1:flex-row">
                      <div className="xs1:w-1/2 w-full">
                        <SelectField
                          formik={formik}
                          label={"From Email"}
                          setFieldValue={formik.setFieldValue}
                          name="from"
                          isSearchable={true}
                          options={domainOptions}
                          onBlur={formik.handleBlur}
                          placeholder="Select From Email"
                          autoFocus={true}
                        />
                      </div>
                      <div className="xs1:w-1/2 w-full">
                        <SelectField
                          formik={formik}
                          label={"Reply To Agent"}
                          setFieldValue={formik.setFieldValue}
                          name="replyTo"
                          isSearchable={true}
                          options={[
                            { value: null, label: "Assigned Agent" },
                            ...agentList,
                          ]}
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          placeholder="Select an agent to reply to"
                        />
                      </div>
                    </div>
                    <div className="overflow-visible">
                      <div className="flex justify-between xs1:space-x-4 space-x-0 flex-col xs1:flex-row">
                        <div className="xs1:w-1/2 w-full">
                          {leadId ? (
                            <>
                              <CustomMultiSelect
                                isOpen={isOpen}
                                options={userEmailList}
                                formik={formik}
                                name="email"
                                label="Select Email"
                                setId={setId}
                                id={id}
                                placeholder="Select Email"
                                defaultSelect={leadId && userEmailList}
                              />
                              {showOptedOutValidation && (
                                <span className="text-orange-600 text-xs">
                                  Please Select Valid Email..!!!
                                </span>
                              )}
                            </>
                          ) : (
                            <>
                              <InputField
                                id="fromName"
                                label="From Name"
                                name="fromName"
                                formik={formik}
                                disabled={
                                  !leadId &&
                                  formik?.values?.from === "assignedAgent"
                                }
                                title={
                                  !leadId &&
                                  formik?.values?.from === "assignedAgent"
                                    ? "If the 'From Email' is the assigned agent, the 'From Name' will be automatically populated with the agent's name."
                                    : ""
                                }
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                autoComplete="fromName"
                                placeholder="Enter From Name"
                              />
                            </>
                          )}
                        </div>
                        <div className="xs1:w-1/2 w-full">
                          <SelectField
                            formik={formik}
                            label="Select Email Template"
                            className="w-full"
                            setFieldValue={formik.setFieldValue}
                            id="template"
                            name="template"
                            isSearchable={true}
                            placeholder="Select Template"
                            options={emailTemplateOptions}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                          />
                        </div>
                      </div>
                      <InputField
                        id="subject"
                        label="Subject"
                        name="subject"
                        formik={formik}
                        onChange={formik.handleChange}
                        autoComplete="subject"
                        placeholder="Enter Subject"
                        disabled={showOptedOutValidation}
                      />
                      <div className="mb-4">
                        <Editor
                          formik={formik}
                          name={"description"}
                          isUnSubscribe={leadId ? false : true}
                          preview={signatureData}
                          isPreview={leadId ? true : false}
                          handlePreview={handlePreview}
                        />
                      </div>
                    </div>
                    <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse space-y-2 sm:space-y-0">
                      <SpinnerButton
                        className="inline-flex w-full justify-center rounded btn-orange  px-3 py-2 text-sm font-semibold text-white shadow-sm sm:ml-3 sm:w-auto"
                        title={"Send"}
                        action={() => {}}
                        type={"submit"}
                        loading={isSendMailLoading || isCountLoading}
                        isDisable={showOptedOutValidation}
                        toolTip={
                          showOptedOutValidation ? "Invalid email" : "send mail"
                        }
                      />
                      <button
                        type="button"
                        className=" w-full justify-center rounded bg-gray px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm bg-gray-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 sm:ml-3 sm:w-auto"
                        onClick={() => {
                          setIsOpen(false);
                          formik.resetForm();
                          setLeadId("");
                        }}
                      >
                        Cancel
                      </button>
                    </div>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
