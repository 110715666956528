import Skeleton from "Components/Common/Fields/Skeleton";
import { isValidArray } from "Modules/util";
import { useEffect, useState } from "react";
import { useGetPropertySearchesByLeadIdQuery } from "Redux/Leads/E-Alerts/alert";
import PropertyMailCard from "./PropertyMailCard";

export default function PropertyMail(props) {
  const {
    lead,
    setIsOpenNestModal = () => {},
    setIsEditPropertyData = () => {},
    setIsDeletePropertyEmailModal = () => {},
    setEditPropertyData = () => {},
    setIsPropertyFilterModal = () => {},
    setPropertyEmailId = () => {},
    isPropertyFilterModal,
  } = props;

  const leadId = lead?.leadResponse?.[0]?._id;
  const [filter, setFilter] = useState({
    page: -1,
    limit: 10,
  });
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [propertyEAlertList, setPropertyEAlertList] = useState([]);

  // Getting all property search data
  const {
    data: getSavedPropertySearchesByLeadId,
    isSavedPropertySearchesByLeadIdLoading,
  } = useGetPropertySearchesByLeadIdQuery({ leadId: leadId, ...filter });

  useEffect(() => {
    setIsOpenNestModal((prev) => ({
      ...prev,
      propertyFilterModal: isPropertyFilterModal,
    }));
  }, [isPropertyFilterModal]);

  // handle EAlert List State
  useEffect(() => {
    if (!isSavedPropertySearchesByLeadIdLoading) {
      const data =
        getSavedPropertySearchesByLeadId?.data?.getSavedPropertySearchesByLeadId?.response?.filter(
          (search) => search?.isEAlert
        ) ?? [];
      // const totalCount =
      //   getSavedPropertySearchesByLeadId?.data?.getSavedPropertySearchesByLeadId
      //     ?.totalCount;
      // setPropertyEAlertList((prevList) => [...prevList, ...newData]);
      setPropertyEAlertList(data);
      // setTotalRecords(totalCount);
      setIsLoadingMore(false);
    }
  }, [getSavedPropertySearchesByLeadId]); 
  return (
    <>
      {/* Card body */}
      <div
        className={`leadDeatils-card-body !h-[350px] rounded-b-2xl overflow-y-auto py-0.5 px-1 `}
        // onScroll={handleScroll}
      >
        {isSavedPropertySearchesByLeadIdLoading ? (
          <Skeleton className="my-1" height={"60px"} counter={3} />
        ) : isValidArray(propertyEAlertList) ? (
          propertyEAlertList?.map((searchProperty, index) => (
            <PropertyMailCard
              key={index + 1}
              searchProperty={searchProperty}
              setEditPropertyData={setEditPropertyData}
              setIsEditPropertyData={setIsEditPropertyData}
              setIsPropertyFilterModal={setIsPropertyFilterModal}
              setPropertyEmailId={setPropertyEmailId}
              setIsDeletePropertyEmailModal={setIsDeletePropertyEmailModal}
            />
          ))
        ) : (
          <div className="flex h-full justify-center items-center text-gray-600 text-xs">
            <span>No property mail or saved search available.</span>
          </div>
        )}

        {/* Render loader at the bottom during data fetching */}
        {isLoadingMore && (
          <Skeleton className="my-1" height={"60px"} counter={3} />
        )}
      </div>
    </>
  );
}
