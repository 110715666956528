import Skeleton from "Components/Common/Fields/Skeleton";
import { isValidArray, showLeadDateTimeFormat } from "Modules/util";
import React from "react";
import EmailToolTipContent from "./EmailToolTipContent";
import Tippy from "@tippyjs/react";
import { EnvelopeIcon, EnvelopeOpenIcon } from "@heroicons/react/24/solid";

export default function History(props) {
  const {
    canDrop,
    emailContainerRef,
    isEmailHistoryListLoading,
    emailHistoryData,
  } = props;
  return (
    <div
      className={`leadDeatils-card-body !h-[390px] rounded-b-2xl overflow-y-auto scrollbar-teal py-2 px-1 w-full space-y-2 ${
        canDrop && "!bg-teal-300 opacity-85"
      }`}
      ref={emailContainerRef}
    >
      {isEmailHistoryListLoading ? (
        <Skeleton counter={5} height={"60px"} className="my-3" />
      ) : isValidArray(emailHistoryData) ? (
        emailHistoryData?.map((email, index) => (
          <Tippy
            key={index}
            allowHTML={true}
            interactive={true}
            placement={"auto"}
            animateFill={true}
            animation={"scale"}
            appendTo={document.body}
            maxWidth={"700px"}
            content={<EmailToolTipContent emailInfo={email} />}
          >
            <div
              key={index}
              className={`m-2 p-2 bg-gray-100 shadow-md rounded-lg space-y-2 text-xs ${
                email?.type === "received"
                  ? "mr-10 my-3 bg-gray-100"
                  : "ml-10 !bg-teal-100"
              }`}
            >
              <div className="xs4:flex justify-between text-xs">
                <span className="flex text-gray space-x-1">
                  <label>To:</label>
                  <label className="flex font-bold !break-all">
                    {" "}
                    <EnvelopeIcon className="w-3 text-teal-600 mr-1" />{" "}
                    {email?.to || "-"}
                  </label>
                </span>
                <span className="text-gray w-full xs4:w-auto flex justify-end">
                  {" "}
                  {showLeadDateTimeFormat(email?.date)}
                </span>
              </div>

              <div>
                <div className="flex text-gray space-x-2 w-full sm:max-w-[75%]">
                  <div className=" text-gray-600 truncate">
                    <span className="mt-1" title={email?.subject}>
                      <b>Subject:</b> {email?.subject}
                    </span>
                  </div>
                </div>
                {email?.status ? (
                  <div className=" text-gray-600 truncate ">
                    <span className="mt-1" title={email?.status}>
                      <b>Status:</b>{" "}
                      <label className="capitalize">{`${email?.status} ${
                        email?.status === "not delivered"
                          ? email?.reason
                            ? ` (${email?.reason})`
                            : ""
                          : ""
                      } `}</label>
                    </span>
                  </div>
                ) : (
                  <></>
                )}
                {email?.type !== "received" ? (
                  <div className="flex mt-1">
                    <span className="text-gray">
                      {email?.isOpened === true ? (
                        <span className="flex items-center  text-teal-600 font-medium">
                          <EnvelopeOpenIcon className="w-4 h-4 mr-1 text-teal-600" />
                          Opened
                          <span className="pl-1 text-gray-600 font-normal text-xs mt-[2px]">
                            {showLeadDateTimeFormat(email?.openedAt)}
                            {email?.openedCount
                              ? `(${email?.openedCount} total opens)`
                              : ""}
                          </span>
                        </span>
                      ) : email?.isOpened === false ? (
                        <span className="flex items-center  text-gray-600 font-medium">
                          <EnvelopeIcon className="w-4 h-4 mr-1 mt-[2px] text-teal-600" />
                          Not Opened
                        </span>
                      ) : (
                        ""
                      )}
                    </span>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </Tippy>
        ))
      ) : (
        <div className="flex justify-center items-center h-full text-gray-600 text-xs">
          No email history available.
        </div>
      )}
    </div>
  );
}
