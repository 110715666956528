import Tippy from "@tippyjs/react";
import dripIcon from "Assets/icons/dripIcon.svg";
import moveIcon from "Assets/icons/moveIcon.svg";
import pauseIcon from "Assets/icons/pauseIcon.svg";
import playIcon from "Assets/icons/playIcon.svg";
import refreshIcon from "Assets/icons/refreshIcon.svg";
import trashIcon from "Assets/icons/trashIcon.svg";
import xIcon from "Assets/icons/xIcon.svg";
import Button from "Components/Common/Buttons/Button";
import Skeleton from "Components/Common/Fields/Skeleton";
import ConfirmationModal from "Components/Common/Modals/ConfirmationModal";
import AssignDripModal from "Components/Leads/Modal/AssignDripModal";
import AssignDripPreviewModal from "Components/Leads/Modal/AssignDripPreviewModal";
import ModifyDripFlowModal from "Components/Leads/Modal/ModifyDripFlowModal";
import RescheduleDripModal from "Components/Leads/Modal/RescheduleDripModal";
import {
  getDripStatusColor,
  getTimeZone,
  isPermission,
  isValidArray,
  isValidObject,
  showErrorMsg,
  showSuccessMsg,
} from "Modules/util";
import {
  useDeleteLeadFromDripsMutation,
  useRemoveLeadFromDripsMutation,
} from "Redux/Drip/drip";
import {
  useGetDripByLeadIdQuery,
  useSkipCurrentNodeMutation,
} from "Redux/Leads/Drip/drip";
import {
  useGenerateOpenAIDripResponseMutation,
  useLeadPlayPauseMutation,
  useNextDripPreviewMutation,
} from "Redux/Leads/lead";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { PulseLoader, ScaleLoader } from "react-spinners";
import DripHoverEditModal from "../LeadModal/DripHoverEditModal";
import TooltipContent from "./DripTooltipContent";
import ReassignDripModal from "Components/Leads/Modal/ReassignDripModal";

export default function Drip(props) {
  const {
    lead,
    drag,
    canDrop,
    filterData,
    setIsOpenNestModal = () => {},
  } = props;

  const [isRemoveDrip, setIsRemoveDrip] = useState(false);
  const [isDeleteDrip, setIsDeleteDrip] = useState(false);
  const [isReAssignDrip, setIsReAssignDrip] = useState(false);
  const [isOpenAddToDripModal, setIsOpenAddToDripModal] = useState(false);
  const [isOpenReassignToDripModal, setIsOpenReassignToDripModal] =
    useState(false);
  const [dripTooltipInfo, setDripTooltipInfo] = useState({});
  const userPermissions = useSelector((state) => state?.user?.permission);
  const timerRef = useRef(null);
  const [dripId, setDripId] = useState(null);
  const [dripPreviewStorage, setDripPreviewStorage] = useState([]);
  const [isPreviewLoading, setIsPreviewLoading] = useState(false);
  const [removeLeadFromDrip] = useRemoveLeadFromDripsMutation();
  const [deleteLeadFromDrip] = useDeleteLeadFromDripsMutation();
  const [leadPlayPause] = useLeadPlayPauseMutation();
  const [savedNodeTime, setSavedNodeTime] = useState({});
  const [playPauseLoading, setPlayPauseLoading] = useState({
    isLoading: false,
    dripId: null,
  });
  const [isRescheduleDripModal, setIsRescheduleDripModal] = useState(false);
  const [isOpenDripHoverEditModal, setIsOpenDripHoverEditModal] =
    useState(false);
  const [nextStepPreview, { isLoading: isNextStepPreviewLodaing }] =
    useNextDripPreviewMutation();
  const [skipCurrentNode, { isLoading: isSkipCurrentNodeLodaing }] =
    useSkipCurrentNodeMutation();

  const [isModifyDripFlowModal, setIsModifyDripFlowModal] = useState(false); // state for modify drip flow modal
  const [scheduleDripData, setScheduleDripData] = useState({});
  const [isOpenAssignDripPreviewModal, setIsAssignDripPreviewModal] =
    useState(false);

  const [dripList, setDripList] = useState([]); // state for Drip list api
  const [dripToReschedule, setDripToReschedule] = useState(null);
  const [dripForModifyFlow, setDripForModifyFlow] = useState(null);
  const [previousDripFlowData, setPreviousDripFlowData] = useState();
  const [isSkipNodeConfirmationModal, setIsSkipNodeConfirmationModal] =
    useState(false);
  const [isSkipLastNodeConfirmationModal, setSkipLastNodeConfirmationModal] =
    useState(false);
  const [savedFreqTime, setSavedFreqTime] = useState(null);

  const leadId = lead?.leadResponse?.[0]?._id;

  // lead wise drips list api
  const {
    data: getDripByLeadId,
    isLoading: isDripListLoading,
    isFetching: isDripListFetching,
    refetch: dripListRefetch,
  } = useGetDripByLeadIdQuery({ _id: leadId }, { skip: leadId ? false : true });

  const [
    generateOpenAIDripPreview,
    { isLoading: isGenerateOpenAIDripPreviewLoading },
  ] = useGenerateOpenAIDripResponseMutation();

  useEffect(() => {
    let dripsResponse =
      getDripByLeadId?.data?.getDripByLeadId?.singleDripResponse?.[0]
        ?.dripsResponse;

    if (!isDripListLoading) {
      if (isValidArray(dripsResponse)) {
        setDripList(
          getDripByLeadId?.data?.getDripByLeadId?.singleDripResponse?.[0]
        );
      } else {
        setDripList([]);
      }
    }
  }, [getDripByLeadId]);

  // useEffect for close nested modal on Escape key press
  useEffect(() => {
    setIsOpenNestModal((prev) => ({
      ...prev,
      addDripModal: isOpenAddToDripModal,
      deleteDripModal: isDeleteDrip,
      reAssignDripModal: isReAssignDrip,
      removeDripModal: isRemoveDrip,
      dripHoverEditModal: isOpenDripHoverEditModal,
      rescheduleDripModal: isRescheduleDripModal,
      modifyDripFlowModal: isModifyDripFlowModal,
      openAssignDripPreviewModal: isOpenAssignDripPreviewModal,
      openReassignToDripModal: isOpenReassignToDripModal,
    }));
  }, [
    isOpenAddToDripModal,
    isDeleteDrip,
    isReAssignDrip,
    isRemoveDrip,
    isOpenDripHoverEditModal,
    isRescheduleDripModal,
    isModifyDripFlowModal,
    isOpenAssignDripPreviewModal,
    isOpenReassignToDripModal,
  ]);

  async function handleRemoveFromDrip() {
    try {
      setPlayPauseLoading({ isLoading: true, dripId: dripId });
      const res = await removeLeadFromDrip({
        dripId: dripId,
        leadId: leadId,
      });
      const removeLead = res?.data?.data?.removeLeadFromDrips;
      if (isValidObject(removeLead)) {
        const { type, message } = removeLead;
        if (type === "success") {
          showSuccessMsg(message);
          setPlayPauseLoading({ isLoading: false, dripId: null });
        } else if (type === "error") {
          showErrorMsg(message);
          setPlayPauseLoading({ isLoading: false, dripId: null });
        }
      } else {
        showErrorMsg("Something went wrong!");
        setPlayPauseLoading({ isLoading: false, dripId: null });
      }
    } catch (error) {
      showErrorMsg("Error occurred while removing lead");
      setPlayPauseLoading({ isLoading: false, dripId: null });
    }
    setIsRemoveDrip(false);
    setDripId(null);
    dripListRefetch();
    flushData();
  }

  async function handleDripPlayPause(dripId, isPlay) {
    let leadPlayPausePayload = {
      dripId: dripId,
      leadId: leadId,
      isPlay: !isPlay,
    };
    setPlayPauseLoading({ isLoading: true, dripId: dripId });
    try {
      const res = await leadPlayPause(leadPlayPausePayload);
      const leadPlayOrPauseDrip = res?.data?.data?.leadPlayOrPause;
      if (isValidObject(leadPlayOrPauseDrip)) {
        const { type, message } = leadPlayOrPauseDrip;
        if (type === "success") {
          showSuccessMsg(message);
          setPlayPauseLoading({ isLoading: false, dripId: null });
        } else if (type === "error") {
          setPlayPauseLoading({ isLoading: false, dripId: null });
          showErrorMsg(message);
        }
      } else {
        setPlayPauseLoading({ isLoading: false, dripId: null });
        showErrorMsg("Error occurred while Drip play/pause.");
      }
    } catch (error) {
      showErrorMsg("Error occurred while Drip play/pause.");
    }
    dripListRefetch();
  }

  function getDripPreview(dripId) {
    let dripWithPreview = dripPreviewStorage?.find(
      (dripData) => dripData?.dripId === dripId
    );

    return dripWithPreview?.preview;
  }

  function getDripSubject(dripId) {
    let dripWithSubject = dripPreviewStorage?.find(
      (dripData) => dripData?.dripId === dripId
    );

    return dripWithSubject?.subject;
  }

  //generate data for drip info tooltip
  function generateDripHoverData(hoveredDripData) {
    if (hoveredDripData?.message) {
      setIsPreviewLoading(false);
    }
    if (hoveredDripData?.dripSortOrder === 2) {
      setDripTooltipInfo({
        dripId: hoveredDripData?.dripId,
        status: "Running",
        type: hoveredDripData?.templateType,
        nodeId: hoveredDripData?.nodeId,
        time: moment(hoveredDripData?.scheduleTime)
          .tz(getTimeZone())
          .format("MMMM Do, YYYY hh:mm A"),
        dripSortOrder: hoveredDripData?.dripSortOrder,
        preview:
          hoveredDripData?.message ?? getDripPreview(hoveredDripData?.dripId),
        isOpenAI: hoveredDripData?.isOpenAI,
        dripTitle: hoveredDripData?.dripTitle,
        subject:
          hoveredDripData?.subject ?? getDripSubject(hoveredDripData?.dripId),
        freqTime: hoveredDripData?.freqTime,
        freqType: hoveredDripData?.freqType,
      });
    } else if (hoveredDripData?.dripSortOrder === 3) {
      setDripTooltipInfo({
        dripId: hoveredDripData?.dripId,
        status: "Pause",
        type: hoveredDripData?.templateType,
        nodeId: hoveredDripData?.nodeId,
        time:
          "on " +
          moment(hoveredDripData?.scheduleTime)
            .tz(getTimeZone())
            .format("MMMM Do, YYYY hh:mm A"),
        dripSortOrder: hoveredDripData?.dripSortOrder,
        preview:
          hoveredDripData?.message ?? getDripPreview(hoveredDripData?.dripId),
        isOpenAI: hoveredDripData?.isOpenAI,
        dripTitle: hoveredDripData?.dripTitle,
        subject:
          hoveredDripData?.subject ?? getDripSubject(hoveredDripData?.dripId),
        freqTime: hoveredDripData?.freqTime,
        freqType: hoveredDripData?.freqType,
      });
    } else if (hoveredDripData?.dripSortOrder === 4) {
      setDripTooltipInfo({
        dripId: hoveredDripData?.dripId,
        status: "Completed",
        time:
          "on " +
          moment(hoveredDripData?.updatedAt)
            .tz(getTimeZone())
            .format("MMMM Do, YYYY"),
        dripSortOrder: hoveredDripData?.dripSortOrder,
        dripTitle: hoveredDripData?.dripTitle,
      });
    } else if (hoveredDripData?.dripSortOrder === 5) {
      setDripTooltipInfo({
        dripId: hoveredDripData?.dripId,
        status: "Terminated",
        time:
          "on " +
          moment(hoveredDripData?.updatedAt)
            .tz(getTimeZone())
            .format("MMMM Do, YYYY"),
        dripSortOrder: hoveredDripData?.dripSortOrder,
        dripTitle: hoveredDripData?.dripTitle,
      });
    }
  }

  async function handleDeleteFromDrip() {
    try {
      const res = await deleteLeadFromDrip({
        dripId: dripId,
        leadId: leadId,
      });
      const deleteDrip = res?.data?.data?.deleteLeadFromDrips;
      if (isValidObject(deleteDrip)) {
        const { type, message } = deleteDrip;
        if (type === "success") {
          showSuccessMsg(message);
        } else if (type === "error") {
          showErrorMsg(message);
        }
      } else {
        showErrorMsg("Something went wrong!");
      }
    } catch (error) {
      showErrorMsg("Error occurred while removing lead");
    }
    setIsRemoveDrip(false);
    setDripId(null);
    dripListRefetch();
    flushData();
  }

  function handleReAssignLeadDrip() {
    setIsOpenReassignToDripModal(true);
  }

  // function of get next step drip preview
  async function handleGetNextStepDripPreview(dripInfo) {
    let dripWithPreview = dripPreviewStorage?.find(
      (dripData) => dripData?.dripId === dripInfo?.dripId
    );

    if (isValidObject(dripWithPreview)) {
      setDripPreviewStorage([
        ...dripPreviewStorage,
        {
          dripId: dripInfo?.dripId,
          preview: dripWithPreview?.preview,
          subject: dripWithPreview?.subject,
        },
      ]);
    }

    if (
      dripInfo?.dripSortOrder !== 4 &&
      dripInfo?.dripSortOrder !== 5 &&
      !isValidObject(dripWithPreview)
    ) {
      let leadId = isValidArray(lead?.leadResponse)
        ? lead?.leadResponse[0]?._id
        : null;

      let payload = {
        dripId: dripInfo?.dripId,
        leadId: leadId,
      };

      try {
        const res = await nextStepPreview(payload);
        const nextDripPreview = res?.data?.data?.nextDripPreview;
        if (isValidObject(nextDripPreview)) {
          const { type, message, preview, subject } = nextDripPreview;
          setDripTooltipInfo((prev) => ({
            ...prev,
            preview: preview,
            subject: subject,
          }));
          if (type === "success") {
            showSuccessMsg(message);
            setIsPreviewLoading(false);
            let dripWithPreview = getDripPreview(payload?.dripId);
            if (!dripWithPreview) {
              setDripPreviewStorage(() => [
                ...dripPreviewStorage,
                { dripId: payload?.dripId, preview: preview, subject: subject },
              ]);
            }
          } else if (type === "error") {
            showErrorMsg(message);
            setIsPreviewLoading(false);
          }
        } else {
          showErrorMsg("Error occurred while generating preview!");
          setIsPreviewLoading(false);
        }
      } catch (error) {
        showErrorMsg("Error occurred while generating preview");
        setIsPreviewLoading(false);
      }
    }
  }

  const handleMouseOver = (dripData) => {
    generateDripHoverData(dripData);
    let dripWithPreview = getDripPreview(dripData?.dripId);

    if (dripTooltipInfo?.preview || dripWithPreview || dripData?.message) {
      setIsPreviewLoading(false);
    } else {
      setIsPreviewLoading(true);
      timerRef.current = setTimeout(() => {
        if (dripData?.isOpenAI) {
          generateOpenAIDripResponse(dripData);
        } else {
          handleGetNextStepDripPreview({
            dripSortOrder: dripData?.dripSortOrder,
            dripId: dripData?.dripId,
          });
        }
      }, 2100); // Set timeout for 2.1 seconds
    }
  };

  async function generateOpenAIDripResponse(dripData) {
    let payload = {
      dripId: dripData?.dripId,
      leadId: lead?.leadResponse?.[0]?._id,
      nodeId: dripData?.nodeId,
      nodeType: dripData?.templateType,
      isSuggestedDrip: false,
    };

    try {
      const res = await generateOpenAIDripPreview(payload);
      const openAIDripPreview = res?.data?.data?.generateOpenAIDripResponse;
      if (isValidObject(openAIDripPreview)) {
        const { type, message, response, subject } = openAIDripPreview;
        setDripTooltipInfo((prev) => ({
          ...prev,
          preview: response,
          subject: subject,
        }));

        if (type === "success") {
          setIsPreviewLoading(false);
          showSuccessMsg(message);
          let dripWithPreview = getDripPreview(payload?.dripId);

          if (dripWithPreview) {
            let storedDrip = dripPreviewStorage;
            let drip = storedDrip.find(
              (drip) => drip?.dripId === dripData?.dripId
            );
            drip.preview = response;

            setDripPreviewStorage([...storedDrip]);
          } else {
            setDripPreviewStorage([
              ...dripPreviewStorage,
              { dripId: dripData?.dripId, preview: response, subject: subject },
            ]);
          }
        } else if (type === "error") {
          showErrorMsg(message);
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        }
      } else {
        showErrorMsg("Error occurred while generating preview!");
      }
    } catch (error) {
      showErrorMsg("Error occurred while generating preview");
    }
  }

  useEffect(() => {
    const dripStepresponse = dripList?.dripStepResponse?.filter(
      (drip) => drip?.dripId === dripTooltipInfo?.dripId
    );
    const dripsResponse = dripList?.dripsResponse?.filter(
      (drip) => drip?.dripId === dripTooltipInfo?.dripId
    );
    setDripToReschedule(mergeDripInfo(dripStepresponse, dripsResponse)?.[0]);

    return () => setDripToReschedule(null);
  }, [dripTooltipInfo]);

  const mergeDripInfo = (dripStepResponse, dripsResponse) => {
    return dripStepResponse?.map((info) => {
      const matchingDrip = dripsResponse?.find(
        (drip) => drip.dripId === info.dripId
      );
      return matchingDrip ? { ...info, ...matchingDrip } : info;
    });
  };

  // handle skip node from preview
  function handleSkipNodes() {
    const ScheduledNodes =
      getDripByLeadId?.data?.getDripByLeadId?.singleDripResponse?.[0]
        ?.dripStepResponse?.[0]?.scheduledNodes;

    if (ScheduledNodes?.length === 1) {
      setSkipLastNodeConfirmationModal(true);
    } else {
      setIsSkipNodeConfirmationModal(true);
    }
  }

  // handle skip node API calling
  async function skipNode() {
    let payload = {
      dripId: dripTooltipInfo?.dripId,
      nodeId: dripTooltipInfo?.nodeId,
      leadId: leadId,
    };
    try {
      const res = await skipCurrentNode(payload);
      const skipCurrentNodeResponse = res?.data?.data?.skipCurrentNode;
      if (isValidObject(skipCurrentNodeResponse)) {
        const { type, message } = skipCurrentNodeResponse;
        if (type === "success") {
          showSuccessMsg(message);
        } else if (type === "error") {
          showErrorMsg(message);
        }
      } else {
        showErrorMsg("Error occurred while Skip node");
      }
    } catch (error) {
      showErrorMsg("Error occurred while Skip node");
    }
  }

  function handleMouseHover(drip) {
    let arg = {};
    let hoveredDrip = dripList?.dripStepResponse?.find(
      (drips) => drips?.dripId === drip?.dripId
    );

    let dripsResponse = dripList?.dripsResponse?.find(
      (drips) => drips?.dripId === drip?.dripId
    );

    let hoveredDripFreqData = dripList?.dripFreqResponse?.find(
      (dripInfo) => dripInfo?.dripId === drip?.dripId
    );

    let dripWithPreview = dripPreviewStorage?.find(
      (dripData) => dripData?.dripId === drip?.dripId
    );

    if (
      !isValidObject(dripWithPreview) &&
      isValidObject(hoveredDrip) &&
      isValidObject(hoveredDripFreqData)
    ) {
      arg = {
        dripTitle: drip?.dripTitle,
        dripId: drip?.dripId,
        message: hoveredDrip?.message,
        dripSortOrder: drip?.dripSortOrder,
        isOpenAI: hoveredDrip?.isOpenAI,
        nodeId: hoveredDrip?.nodeId,
        templateType: hoveredDrip?.templateType,
        scheduleTime: hoveredDrip?.scheduleTime,
        subject: hoveredDrip?.subject,
        freqTime: hoveredDripFreqData?.freqTime,
        freqType: hoveredDripFreqData?.freqType,
        updatedAt: dripsResponse?.updatedAt,
      };
    } else {
      arg = {
        dripTitle: drip?.dripTitle,
        dripSortOrder: drip?.dripSortOrder,
        dripId: drip?.dripId,
        message: hoveredDrip?.message,
        isOpenAI: hoveredDrip?.isOpenAI,
        nodeId: hoveredDrip?.nodeId,
        templateType: hoveredDrip?.templateType,
        scheduleTime: hoveredDrip?.scheduleTime,
        subject: hoveredDrip?.subject,
        freqTime: hoveredDripFreqData?.freqTime,
        freqType: hoveredDripFreqData?.freqType,
        updatedAt: dripsResponse?.updatedAt,
      };
    }
    if (!playPauseLoading?.isLoading) {
      handleMouseOver(arg);
    }
  }

  function flushData() {
    setSavedNodeTime({});
    setSavedFreqTime({});
    setPreviousDripFlowData({});
    setScheduleDripData(null);
  }

  return (
    <div>
      <ConfirmationModal
        isModalOpen={isSkipNodeConfirmationModal}
        setModalOpen={setIsSkipNodeConfirmationModal}
        handleAction={() => skipNode()}
        message="You want to Skip this node?"
      />
      <ConfirmationModal
        isModalOpen={isSkipLastNodeConfirmationModal}
        setModalOpen={setSkipLastNodeConfirmationModal}
        handleAction={() => skipNode()}
        message="This is the last node. Skipping it will automatically complete the drip. Proceed?"
      />
      <ConfirmationModal
        isModalOpen={isRemoveDrip}
        setModalOpen={setIsRemoveDrip}
        handleAction={() => handleRemoveFromDrip()}
        message="You want to terminate this drip?"
      />
      <ConfirmationModal
        isModalOpen={isDeleteDrip}
        setModalOpen={setIsDeleteDrip}
        handleAction={() => handleDeleteFromDrip()}
        message="You want to delete this drip?"
      />
      <ConfirmationModal
        isModalOpen={isReAssignDrip}
        setModalOpen={setIsReAssignDrip}
        handleAction={() => handleReAssignLeadDrip()}
        message="You want to re-assign this drip?"
      />
      {isOpenAddToDripModal && (
        <AssignDripModal
          isOpen={isOpenAddToDripModal}
          setIsOpen={setIsOpenAddToDripModal}
          lead={lead}
          filterData={filterData}
          leadId={[leadId]}
          setSavedFreqTime={setSavedFreqTime}
          setIsModifyDripFlowModal={setIsModifyDripFlowModal}
          setDripForModifyFlow={setDripForModifyFlow}
          setSavedNodeTime={setSavedNodeTime}
          ActiveDripList={dripList}
          flushData={flushData}
        />
      )}
      {isOpenReassignToDripModal && (
        <ReassignDripModal
          isOpen={isOpenReassignToDripModal}
          setIsOpen={setIsOpenReassignToDripModal}
          lead={lead}
          filterData={filterData}
          leadId={[leadId]}
          setSavedFreqTime={setSavedFreqTime}
          setIsModifyDripFlowModal={setIsModifyDripFlowModal}
          setDripForModifyFlow={setDripForModifyFlow}
          setSavedNodeTime={setSavedNodeTime}
          selectedDripForAssign={dripForModifyFlow}
          flushData={flushData}
        />
      )}
      {isOpenAssignDripPreviewModal && (
        <AssignDripPreviewModal
          isOpen={isOpenAssignDripPreviewModal}
          setIsOpen={setIsAssignDripPreviewModal}
          scheduleDripData={scheduleDripData}
          selectedDripForAssign={dripForModifyFlow}
          filterData={filterData}
          lead={lead}
          setIsModifyDripFlowModal={setIsModifyDripFlowModal}
          setPreviousDripFlowData={setPreviousDripFlowData}
          savedNodeTime={savedNodeTime}
          savedFreqTime={savedFreqTime}
          flushData={flushData}
          previousDripFlowData={previousDripFlowData}
        />
      )}
      {isOpenDripHoverEditModal && (
        <DripHoverEditModal
          refetch={dripListRefetch}
          isOpen={isOpenDripHoverEditModal}
          setIsOpen={setIsOpenDripHoverEditModal}
          dripTooltipInfo={dripTooltipInfo}
          generateOpenAIDripResponse={generateOpenAIDripResponse}
          isGenerateOpenAIDripPreviewLoading={
            isGenerateOpenAIDripPreviewLoading
          }
          leadId={
            isValidArray(lead?.leadResponse) ? lead?.leadResponse[0]?._id : null
          }
        />
      )}
      {isRescheduleDripModal && (
        <RescheduleDripModal
          isOpen={isRescheduleDripModal}
          setIsOpen={setIsRescheduleDripModal}
          dripToReschedule={dripToReschedule}
          leadId={leadId}
          dripListRefetch={dripListRefetch}
        />
      )}
      {isModifyDripFlowModal && (
        <ModifyDripFlowModal
          isOpen={isModifyDripFlowModal}
          setIsOpen={setIsModifyDripFlowModal}
          dripId={dripForModifyFlow}
          scheduleDripData={scheduleDripData}
          setScheduleDripData={setScheduleDripData}
          savedFreqTime={savedFreqTime}
          setIsAssignDripPreviewModal={setIsAssignDripPreviewModal}
          previousDripFlowData={previousDripFlowData ?? {}}
          savedNodeTime={savedNodeTime}
          flushData={flushData}
        />
      )}

      <div className={`w-full sm:!min-w-[475px] shadow-md rounded-2xl`}>
        <div
          className={`bg-purple-400 !h-[40px] rounded-t-2xl flex items-center justify-between px-4 ${
            canDrop && "!bg-purple-300 opacity-85"
          }`}
        >
          <div className="space-x-2 flex items-center ">
            <div className="flex">
              <div className="bg-white w-0.5"></div>
              <div className="text-white pl-[7px] font-medium">Drip</div>
            </div>
          </div>
          <div className="flex space-x-3">
            {isPermission(userPermissions, "schedule_drip_leads") && (
              <Button
                label={"Add"}
                title={"Add to drip"}
                className="py-1 px-3 bg-purple-600 text-white shadow-sm shadow-gray-300 text-sm font-medium rounded-lg"
                action={() => {
                  setIsOpenAddToDripModal(true);
                }}
              />
            )}
            <div
              className="bg-purple-600 rounded-md flex items-center px-1.5 h-7 w-7	cursor-move"
              ref={drag}
            >
              <img
                alt="edit-icon"
                src={moveIcon}
                className="h-4 w-4 rounded-md filter invert brightness-0"
                title={"Move Segment"}
              />
            </div>
          </div>
        </div>
        <div
          className={`leadDeatils-card-body !h-[390px] rounded-b-2xl overflow-y-auto scrollbar-purple space-y-3 py-2 px-1 text-xs ${
            canDrop && "!bg-purple-300 opacity-85"
          }`}
        >
          {isDripListLoading ? (
            <Skeleton counter={5} height={"60px"} className="my-3" />
          ) : isValidArray(dripList?.dripsResponse) ? (
            <>
              {dripList?.dripsResponse?.map((drip, index) => (
                <Tippy
                  key={index}
                  allowHTML={true}
                  interactive={true}
                  placement={"auto"}
                  animateFill={true}
                  animation={"scale"}
                  maxWidth={"600px"}
                  appendTo={document.body}
                  zIndex={900}
                  content={
                    <TooltipContent
                      dripTooltipInfo={dripTooltipInfo}
                      isNextStepPreviewLodaing={isNextStepPreviewLodaing}
                      isPreviewLoading={isPreviewLoading}
                      isGenerateOpenAIDripPreviewLoading={
                        isGenerateOpenAIDripPreviewLoading
                      }
                      setIsRescheduleDripModal={setIsRescheduleDripModal}
                      generateOpenAIDripResponse={generateOpenAIDripResponse}
                      setIsOpenDripHoverEditModal={setIsOpenDripHoverEditModal}
                      PulseLoader={PulseLoader}
                      handleSkipNodes={handleSkipNodes}
                      isSkipCurrentNodeLodaing={isSkipCurrentNodeLodaing}
                    />
                  }
                >
                  <div>
                    <div
                      className="flex justify-between m-2 p-2 bg-gray-100 rounded-md shadow-md border border-gray-2 m-200 "
                      onMouseOver={() => handleMouseHover(drip)}
                      onMouseOut={() => {
                        clearTimeout(timerRef.current);
                      }}
                    >
                      <div className="text-gray-800 text-sm flex space-x-1 items-center w-full sm:w-[70%]">
                        {drip?.dripSortOrder !== 1 ? (
                          <svg
                            fill="currentColor"
                            width="800px"
                            height="800px"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                            className={`h-5 w-5  ${
                              getDripStatusColor?.[drip?.dripSortOrder]
                            } 
                `}
                          >
                            <path d="M5.3,11.214A6.791,6.791,0,0,0,10.792,22h2.416A6.791,6.791,0,0,0,18.7,11.214L12,2Zm5.946,8.036a5.24,5.24,0,0,1-4.631-2.779,1,1,0,1,1,1.766-.942,3.239,3.239,0,0,0,2.865,1.721,1,1,0,0,1,0,2Z" />
                          </svg>
                        ) : (
                          <span>
                            <img
                              alt="File Upload"
                              src={dripIcon}
                              className="h-[17.5px] w-[17.5px] "
                              draggable={false}
                            />
                          </span>
                        )}
                        {isPermission(userPermissions, "edit_drip") ? (
                          <Link
                            className="sm:!line-clamp-1 !line-clamp-2 w-full sm:max-w-[90%] text-xs hover:underline"
                            to={`/drip-flow/${drip?.dripId}`}
                          >
                            {drip?.dripTitle}
                          </Link>
                        ) : (
                          <div className="!line-clamp-1 w-full sm:max-w-[90%] text-xs">
                            {drip?.dripTitle}
                          </div>
                        )}
                      </div>
                      <div className="space-x-2 flex justify-end h-min sm:ml-2">
                        {drip?.leadDripStatus === "completed" ? (
                          <div className="flex items-center space-x-2 !shrink-0">
                            <span className=" text-blue-500">Completed</span>
                            {isPermission(
                              userPermissions,
                              "schedule_drip_leads"
                            ) && (
                              <>
                                <div className="bg-white rounded-md p-1 !w-6 !h-6 !shrink-0">
                                  <img
                                    alt="delete-icon"
                                    src={trashIcon}
                                    className="!h-4 !w-4 hover:cursor-pointer rounded-md !shrink-0"
                                    onClick={() => {
                                      setDripPreviewStorage([]);
                                      setIsDeleteDrip(true);
                                      setDripId(drip?.dripId);
                                    }}
                                    title={"Delete Drip"}
                                    isDisabled={false}
                                  />
                                </div>
                                <div className="bg-purple-600 rounded-md p-1 !shrink-0">
                                  <img
                                    alt="refresh-icon"
                                    src={refreshIcon}
                                    className="h-4 w-4 hover:cursor-pointer rounded-md filter invert brightness-0 !shrink-0"
                                    onClick={() => {
                                      setDripPreviewStorage([]);
                                      setIsReAssignDrip(true);
                                      setDripForModifyFlow(drip?.dripId);
                                      setPreviousDripFlowData({
                                        scheduledNodes:
                                          drip?.scheduledNodes ?? [],
                                        skipNodes: drip?.skipNodes ?? [],
                                      });
                                    }}
                                    title={"Re-Assign Drip"}
                                    isDisabled={false}
                                  />
                                </div>
                              </>
                            )}
                          </div>
                        ) : drip?.isTerminated ? (
                          <div className="flex space-x-2">
                            <span className="text-sm text-gray-500">
                              Terminated
                            </span>
                            {isPermission(
                              userPermissions,
                              "schedule_drip_leads"
                            ) && (
                              <>
                                {(playPauseLoading?.isLoading &&
                                  playPauseLoading?.dripId === drip?.dripId) ||
                                isDripListFetching ? (
                                  <div className="bg-purple-600 rounded-md p-1 !shrink-0">
                                    <ScaleLoader
                                      className="cursor-wait !shrink-0"
                                      color="#ffffff"
                                      size={5}
                                      width={3}
                                      height={11}
                                    />
                                  </div>
                                ) : (
                                  <div className="bg-purple-600 w-6 rounded-md p-1">
                                    <img
                                      alt="refresh-icon"
                                      src={refreshIcon}
                                      className="h-4 w-4 hover:cursor-pointer rounded-md filter invert brightness-0"
                                      onClick={() => {
                                        setDripPreviewStorage([]);
                                        setIsReAssignDrip(true);
                                        setDripForModifyFlow(drip?.dripId);
                                        setPreviousDripFlowData(
                                          drip?.scheduledNodes,
                                          drip?.skipNodes
                                        );
                                      }}
                                      title={"Re-Assign Drip"}
                                      isDisabled={false}
                                    />
                                  </div>
                                )}
                              </>
                            )}
                          </div>
                        ) : (
                          <>
                            {isPermission(
                              userPermissions,
                              "schedule_drip_leads"
                            ) ? (
                              <>
                                {(playPauseLoading?.isLoading &&
                                  playPauseLoading?.dripId === drip?.dripId) ||
                                isDripListFetching ? (
                                  <div className="bg-purple-600 rounded-md p-1 !shrink-0">
                                    <ScaleLoader
                                      className="cursor-wait !shrink-0"
                                      color="#ffffff"
                                      size={5}
                                      width={3}
                                      height={11}
                                    />
                                  </div>
                                ) : (
                                  <div className="bg-purple-600 !w-6 !h-6 rounded-md p-1 !shrink-0">
                                    <img
                                      alt="refresh-icon"
                                      src={xIcon}
                                      className="h-4 w-4 hover:cursor-pointer rounded-md filter invert brightness-0 "
                                      onClick={() => {
                                        if (
                                          isPermission(
                                            userPermissions,
                                            "schedule_drip_leads"
                                          )
                                        ) {
                                          setDripPreviewStorage([]);
                                          setIsRemoveDrip(true);
                                          setDripId(drip?.dripId);
                                        }
                                      }}
                                      title={
                                        isPermission(
                                          userPermissions,
                                          "schedule_drip_leads"
                                        )
                                          ? "Terminate Drip"
                                          : ""
                                      }
                                      isDisabled={false}
                                    />
                                  </div>
                                )}
                                <div className="bg-purple-600 rounded-md p-1 flex items-center !shrink-0">
                                  {(playPauseLoading?.isLoading &&
                                    playPauseLoading?.dripId ===
                                      drip?.dripId) ||
                                  isDripListFetching ? (
                                    <ScaleLoader
                                      className="cursor-wait !shrink-0"
                                      color="#ffffff"
                                      size={5}
                                      width={3}
                                      height={11}
                                    />
                                  ) : (
                                    <>
                                      <img
                                        alt="refresh-icon"
                                        src={
                                          drip?.isPlay ? pauseIcon : playIcon
                                        }
                                        className={`filter invert brightness-0 hover:cursor-pointer rounded-md ${
                                          drip?.isPlay ? "h-3 w-4" : "h-4 w-4"
                                        }`}
                                        onClick={() => {
                                          if (!playPauseLoading?.isLoading) {
                                            if (
                                              isPermission(
                                                userPermissions,
                                                "schedule_drip_leads"
                                              )
                                            ) {
                                              handleDripPlayPause(
                                                drip?.dripId,
                                                drip?.isPlay
                                              );
                                              let hoveredDrip =
                                                lead?.dripStepResponse?.find(
                                                  (drips) =>
                                                    drips?.dripId ===
                                                    drip?.dripId
                                                );
                                              setDripPreviewStorage([]);
                                              generateDripHoverData({
                                                ...hoveredDrip,
                                                ...drip,
                                              });
                                            }
                                          }
                                        }}
                                        title={
                                          isPermission(
                                            userPermissions,
                                            "schedule_drip_leads"
                                          )
                                            ? drip?.isPlay
                                              ? "Pause Drip"
                                              : "Play Drip"
                                            : ""
                                        }
                                        isDisabled={false}
                                      />
                                    </>
                                  )}
                                </div>
                              </>
                            ) : (
                              <></>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </Tippy>
              ))}
            </>
          ) : (
            <div className="flex justify-center h-full text-gray-600 ">
              <span className="self-center">No drips available.</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
