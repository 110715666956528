import { Tab } from "@headlessui/react";
import moveIcon from "Assets/icons/moveIcon.svg";
import ConfirmationModal from "Components/Common/Modals/ConfirmationModal";
import { isValidObject, showErrorMsg, showSuccessMsg } from "Modules/util";
import { useEffect, useState } from "react";
import { useDeleteSavedSearchEAlertMutation } from "Redux/Leads/E-Alerts/alert";
import PropertyFilterModal from "./PropertyFilterModal";
import PropertySearch from "./PropertySearches/PropertySearch";
import PropertyVisit from "./PropertyVisits/PropertyVisit";

export default function Property(props) {
  const { drag, canDrop, lead, setIsOpenNestModal = () => {} } = props;
  const [isPropertyFilterModal, setIsPropertyFilterModal] = useState(false);
  const [isRefetchPropertySearch, setIsRefetchPropertySearch] = useState(true);
  const [editPropertyData, setEditPropertyData] = useState({});
  const [isEditPropertyData, setIsEditPropertyData] = useState(false);
  const [isDeletePropertyEmailModal, setIsDeletePropertyEmailModal] =
    useState(false);
  const [isPropertyEmailId, setPropertyEmailId] = useState(null);
  const [deletePropertyEmail] = useDeleteSavedSearchEAlertMutation();

  useEffect(() => {
    setIsOpenNestModal((prev) => ({
      ...prev,
      propertyFilterModal: isPropertyFilterModal,
    }));
  }, [isPropertyFilterModal]);

  // handle delete E-Alert
  async function handleDeleteEAlert(id) {
    let payload = {
      id: id,
    };
    try {
      const res = await deletePropertyEmail(payload);
      const deleteSavedSearchEAlertResponse =
        res?.data?.data?.deleteSavedSearchEAlert;
      if (isValidObject(deleteSavedSearchEAlertResponse)) {
        const { type, message } = deleteSavedSearchEAlertResponse;
        if (type === "success") {
          setIsRefetchPropertySearch(true);
          showSuccessMsg(message);
          setPropertyEmailId(null);
          setIsDeletePropertyEmailModal(false);
        } else if (type === "error") {
          showErrorMsg(message);
        }
      } else {
        showErrorMsg("Error occurred while delete e-alert");
      }
    } catch (error) {
      showErrorMsg("Error occurred while delete e-alert");
    }
  }

  return (
    <>
      <PropertyFilterModal
        isOpen={isPropertyFilterModal}
        setIsOpen={setIsPropertyFilterModal}
        lead={lead}
        setIsRefetchPropertySearch={setIsRefetchPropertySearch}
        editPropertyData={editPropertyData}
        isEditPropertyData={isEditPropertyData}
        setIsEditPropertyData={setIsEditPropertyData}
        setEditPropertyData={setEditPropertyData}
      />
      <ConfirmationModal
        isModalOpen={isDeletePropertyEmailModal}
        setModalOpen={setIsDeletePropertyEmailModal}
        handleAction={() => handleDeleteEAlert(isPropertyEmailId)}
      />
      <div className="w-full sm:!min-w-[475px] rounded-2xl shadow-md">
        <Tab.Group>
          <div
            className={`leadDeatils-card-header sm:!h-[40px] rounded-t-2xl flex items-center justify-between px-4 ${
              canDrop && "!bg-orange-300 opacity-85"
            }`}
          >
            <Tab.List className="flex flex-wrap sm:flex-nowrap items-center xs2:space-y-0 space-y-1 xs2:mt-0 mt-1">
              <div className="bg-white w-0.5 h-[24px] hidden sm:inline-block mr-1"></div>
              <Tab
                className={({ selected }) =>
                  `cursor-pointer px-3 focus:outline-none focus:ring-0 text-sm lg:py-1 ${
                    selected
                      ? "border-0 bg-orange-500 text-white rounded-full"
                      : "text-slate-200 hover:bg-orange-500 hover:text-white hover:rounded-full"
                  }`
                }
                onClick={() => setIsRefetchPropertySearch(true)}
              >
                Property Searches
              </Tab>
              <div className="bg-white w-[1px] h-[24px] mx-2 mt-1 hidden sm:inline-block"></div>
              <Tab
                className={({ selected }) =>
                  `cursor-pointer px-3 focus:outline-none focus:ring-0 text-sm lg:py-1 ${
                    selected
                      ? "border-0 bg-orange-500 text-white rounded-full"
                      : "text-slate-200 hover:bg-orange-500 hover:text-white hover:rounded-full"
                  }`
                }
              >
                Property Visits
              </Tab>
            </Tab.List>
            <div className="flex space-x-2 mt-2 sm:mt-0 flex-wrap sm:flex-nowrap items-center justify-end">
              <button
                className="bg-orange-600 text-white px-3 py-1 rounded-md text-xs sm:text-sm font-medium"
                onClick={() => setIsPropertyFilterModal(true)}
              >
                Create new search
              </button>
              <div
                className="bg-orange-600 rounded-md flex items-center p-1.5 h-7 w-7 cursor-move"
                ref={drag}
              >
                <img
                  alt="edit-icon"
                  src={moveIcon}
                  className="h-4 w-4 rounded-md filter invert brightness-0"
                  title={"Move Segment"}
                />
              </div>
            </div>
          </div>
          <Tab.Panels>
            <Tab.Panel>
              {/* Property Search Body */}
              <PropertySearch
                lead={lead}
                canDrop={canDrop}
                setIsRefetchPropertySearch={setIsRefetchPropertySearch}
                setEditPropertyData={setEditPropertyData}
                setIsPropertyFilterModal={setIsPropertyFilterModal}
                setIsEditPropertyData={setIsEditPropertyData}
                isRefetchPropertySearch={isRefetchPropertySearch}
                isPropertyEmailId={isPropertyEmailId}
                setIsDeletePropertyEmailModal={setIsDeletePropertyEmailModal}
                setIsOpenNestModal={setIsOpenNestModal}
                isPropertyFilterModal={isPropertyFilterModal}
                setPropertyEmailId={setPropertyEmailId}
              />
            </Tab.Panel>
            <Tab.Panel>
              {/* Property Visit Body */}
              <PropertyVisit lead={lead} canDrop={canDrop} />
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      </div>
    </>
  );
}
