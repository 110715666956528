import Checkbox from "Components/Common/Checkbox/Checkbox";
import InputField from "Components/Common/Fields/InputField";
import SelectField from "Components/Common/Fields/SelectField";
import {
  bathTypeOptions,
  bedTypeOptions,
  communityFeaturesCheckboxes,
  exteriorFeaturesCheckboxs,
  filterCountOptions,
  financingCheckboxs,
  frequencyOptions,
  houseStyleCheckboxs,
  houseViewCheckboxs,
  interiorFeaturesCheckboxs,
  lotSizeOptions,
  propertyCheckboxs,
  propertyFeatureCheckboxs,
  squareFeetOptions,
  waterFrontCheckboxs,
  yearBuiltOptions,
} from "Components/Leads/assetData";
import { ensureYearsUpToCurrent, isValidArray } from "Modules/util";
import { showFieldError } from "Modules/validation";
import { useEffect, useRef, useState } from "react";
import PropertyDisclosure from "./PropertyDisclosure";

export default function AddPropertyFilter(props) {
  const { formik, lead, isEditPropertyData, editPropertyData, domainOptions } =
    props;
  const [emailList, setEmailList] = useState([]);
  const scrollDivRef = useRef(null);

  // Key press handler to prevent non-numeric characters
  const handleKeyPress = (e) => {
    const charCode = e.charCode || e.keyCode;
    const char = String.fromCharCode(charCode);

    // Allow numbers and prevent others
    if (!/^[0-9]$/.test(char)) {
      e.preventDefault();
    }
  };

  useEffect(() => {
    let emails = [];
    if (isValidArray(lead?.leadResponse?.[0]?.emailStatusDetail)) {
      emails = lead?.leadResponse?.[0]?.emailStatusDetail
        ?.filter(
          (data) =>
            data?.emailStatus?.toLowerCase() === "valid" ||
            data?.emailStatus?.toLowerCase() === "unknown"
        )
        ?.map((item) => item?.email);
      if (isValidArray(emails) && !isValidArray(formik?.values?.emailsToSend)) {
        handleChangeCheckBox(true, emails?.[0]);
      }
    }
    setEmailList(emails);
  }, [lead]);

  useEffect(() => {
    if (scrollDivRef.current) {
      scrollDivRef.current.scrollTo({
        top: 0, // Ensures the div is scrolled to the top
        behavior: "auto", // No animation for the initial position
      });
    }
  }, []);

  // handle check box change
  function handleChangeCheckBox(isChecked, email) {
    const oldValue = formik?.values?.emailsToSend ?? [];
    formik.setFieldValue(
      "emailsToSend",
      isChecked
        ? [...oldValue, email]
        : formik?.values?.emailsToSend?.filter((item) => item !== email)
    );
  }

  const handleDropdownOpen = () => {
    if (scrollDivRef.current) {
      // Delay the scroll action to ensure the container is fully expanded
      setTimeout(() => {
        scrollDivRef.current.scrollTo({
          top: scrollDivRef.current.scrollHeight,
          behavior: "smooth",
        });
      }, 100); // Delay of 100ms (you can adjust this value if needed)
    }
  };

  return (
    <div className="!h-[420px] overflow-auto p-1" ref={scrollDivRef}>
      {/* Home Type */}
      <label className="font-semibold block text-gray-700">Home Type</label>
      <div className="my-2">
        <div className="text-orange-500 space-y-1.5 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-2 my-3">
          {propertyCheckboxs?.map((check, index) => (
            <Checkbox
              key={index}
              id={check?.variable}
              name={check?.variable}
              label={check?.label}
              checked={formik?.values?.[check?.variable]}
              onChange={(e) =>
                formik?.setFieldValue(check?.variable, e.target.checked)
              }
            />
          ))}
        </div>
      </div>

      <div className="border-t-2 my-3 xs2:flex xs2:items-start" />
      {/* Price Range */}
      <div className="flex flex-col md:flex-row md:items-center w-full  ">
        <label className="flex font-semibold text-gray-700 md:mb-0 md:!mr-[41px] ">
          Price Range
        </label>
        <div className="flex flex-col my-2 ">
          <div className="sm2:flex sm2:items-start sm2:space-x-3">
            <div className="w-full lg:w-80 flex items-start">
              <div className="bg-gray-200 w-6 text-center rounded-md shadow-sm !rounded-r-none border border-gray-400 border-r-0 p-1 font-semibold text-gray-500 mt-2">
                $
              </div>
              <InputField
                formik={formik}
                id="minPrice"
                name="minPrice"
                type="number"
                value={formik?.values?.minPrice}
                onChange={formik?.handleChange}
                onBlur={formik?.handleBlur}
                autoComplete="minPrice"
                placeholder="Price min"
                className="!ring-0 !border !rounded-l-none shadow-sm !border-gray-400 !border-l-0 !p-1"
                min={0}
                onKeyPress={handleKeyPress} // Attach key press handler
              />
              <div
                style={{
                  height: !(formik.touched?.minPrice && formik.errors?.minPrice)
                    ? "40px"
                    : "0px",
                }}
              ></div>
            </div>
            <span className="text-gray-500 sm:mt-3 flex items-center justify-center">
              to
            </span>
            <div className="w-full lg:w-80 flex items-start">
              <div className="bg-gray-200 w-6 text-center rounded-md shadow-sm !rounded-r-none border border-gray-400 border-r-0 p-1 font-semibold text-gray-500 mt-2">
                $
              </div>
              <InputField
                formik={formik}
                id="maxPrice"
                name="maxPrice"
                type="number"
                value={formik?.values?.maxPrice}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                autoComplete="maxPrice"
                placeholder="Price max"
                className="!ring-0 !border !rounded-l-none shadow-sm !border-gray-400 !border-l-0 !p-1"
                min={0}
                onKeyPress={handleKeyPress} // Attach key press handler
              />
              <div
                style={{
                  height: !(formik.touched?.maxPrice && formik.errors?.maxPrice)
                    ? "40px"
                    : "0px",
                }}
              ></div>
            </div>
          </div>
        </div>
      </div>

      <div className="border-t-2 overflow-visible">
        {/* First Row */}
        <div className="md:flex md:space-x-4 mt-3">
          {/* Bedrooms */}
          <div className="flex flex-col md:flex-row md:items-center w-full md:w-1/2">
            <label className="font-semibold text-gray-700 mb-2 md:mb-0 md:mr-4 xs:!mr-[54px]">
              Bedrooms
            </label>
            <div className="w-full md:w-36">
              <SelectField
                formik={formik}
                label=""
                setFieldValue={formik?.setFieldValue}
                name="bedrooms"
                isSearchable={false}
                options={bedTypeOptions}
                onBlur={formik.handleBlur}
                placeholder="Select Bed"
                autoFocus={true}
              />
            </div>
          </div>
          {/* Bathrooms */}
          <div className="flex flex-col md:flex-row md:items-center w-full md:w-1/2 mt-4 md:mt-0">
            <label className="font-semibold text-gray-700 mb-2 md:mb-0 md:mr-4 xs:!mr-[28px]">
              Bathrooms
            </label>
            <div className="w-full md:w-36">
              <SelectField
                formik={formik}
                label=""
                setFieldValue={formik?.setFieldValue}
                name="bathrooms"
                isSearchable={false}
                options={bathTypeOptions}
                onBlur={formik.handleBlur}
                placeholder="Select Bath"
                autoFocus={true}
              />
            </div>
          </div>
        </div>

        {/* Second Row */}
        <div className="md:flex md:space-x-4 mt-4">
          {/* Garage Spaces */}
          <div className="flex flex-col lg:flex-row lg:items-center w-full md:w-1/2">
            <label className="font-semibold text-gray-700 mb-2 md:mb-0 md:mr-5">
              Garage Spaces
            </label>
            <div className="flex flex-col sm:flex-row sm:space-x-2">
              <div className="w-full md:w-36">
                <SelectField
                  formik={formik}
                  label=""
                  setFieldValue={formik?.setFieldValue}
                  name="garageSpacesMin"
                  isSearchable={false}
                  options={filterCountOptions}
                  onBlur={formik.handleBlur}
                  placeholder="Select Spaces"
                  autoFocus={true}
                />
              </div>
              <span className="text-gray-500 flex items-center justify-center">
                to
              </span>
              <div className="w-full md:w-36">
                <SelectField
                  formik={formik}
                  label=""
                  setFieldValue={formik?.setFieldValue}
                  name="garageSpacesMax"
                  isSearchable={false}
                  options={filterCountOptions}
                  onBlur={formik.handleBlur}
                  placeholder="Select Spaces"
                  autoFocus={true}
                />
              </div>
            </div>
          </div>

          {/* Stories */}
          <div className="flex flex-col lg:flex-row lg:items-center w-full md:w-1/2 mt-4 md:mt-0">
            <label className="font-semibold text-gray-700 mb-2 md:mb-0 md:mr-4 xs:!mr-[58px]">
              Stories
            </label>
            <div className="flex flex-col sm:flex-row sm:space-x-2">
              <div className="w-full md:w-36">
                <SelectField
                  formik={formik}
                  label=""
                  setFieldValue={formik?.setFieldValue}
                  name="storiesMin"
                  isSearchable={false}
                  options={filterCountOptions}
                  onBlur={formik.handleBlur}
                  placeholder="Select Stories"
                  autoFocus={true}
                />
              </div>
              <span className="text-gray-500 flex items-center justify-center">
                to
              </span>
              <div className="w-full md:w-36">
                <SelectField
                  formik={formik}
                  label=""
                  setFieldValue={formik?.setFieldValue}
                  name="storiesMax"
                  isSearchable={false}
                  options={filterCountOptions}
                  onBlur={formik.handleBlur}
                  placeholder="Select Stories"
                  autoFocus={true}
                />
              </div>
            </div>
          </div>
        </div>
        {/* Third Row */}
        <div className="md:flex md:space-x-4 mt-4">
          {/* Year Built */}
          <div className="flex flex-col lg:flex-row lg:items-center w-full md:w-1/2">
            <label className="font-semibold text-gray-700 mb-2 md:mb-0 md:mr-4 xs:!mr-[55px]">
              Year Built
            </label>
            <div className="flex flex-col sm:flex-row sm:space-x-2">
              <div className="w-full md:w-36">
                <SelectField
                  formik={formik}
                  label=""
                  setFieldValue={formik?.setFieldValue}
                  name="yearBuiltMin"
                  isSearchable={false}
                  options={ensureYearsUpToCurrent(yearBuiltOptions)}
                  onBlur={formik.handleBlur}
                  placeholder="Select Year"
                  autoFocus={true}
                />
              </div>
              <span className="text-gray-500 flex items-center justify-center">
                to
              </span>
              <div className="w-full md:w-36">
                <SelectField
                  formik={formik}
                  label=""
                  setFieldValue={formik?.setFieldValue}
                  name="yearBuiltMax"
                  isSearchable={false}
                  options={ensureYearsUpToCurrent(yearBuiltOptions)}
                  onBlur={formik.handleBlur}
                  placeholder="Select Year"
                  autoFocus={true}
                />
              </div>
            </div>
          </div>

          {/* Square Feet */}
          <div className="flex flex-col lg:flex-row lg:items-center w-full md:w-1/2 mt-4 md:mt-0">
            <label className="font-semibold text-gray-700 mb-2 md:mb-0 md:mr-[20px]">
              Square Feet
            </label>
            <div className="flex flex-col sm:flex-row sm:space-x-2">
              <div className="w-full md:w-36">
                <SelectField
                  formik={formik}
                  label=""
                  setFieldValue={formik?.setFieldValue}
                  name="squareFeetMin"
                  isSearchable={false}
                  options={squareFeetOptions}
                  onBlur={formik.handleBlur}
                  placeholder="Select Value"
                  autoFocus={true}
                />
              </div>
              <span className="text-gray-500 flex items-center justify-center">
                to
              </span>
              <div className="w-full md:w-36">
                <SelectField
                  formik={formik}
                  label=""
                  setFieldValue={formik?.setFieldValue}
                  name="squareFeetMax"
                  isSearchable={false}
                  options={squareFeetOptions}
                  onBlur={formik.handleBlur}
                  placeholder="Select Value"
                  autoFocus={true}
                />
              </div>
            </div>
          </div>
        </div>
        {/* Forth Row */}
        <div className="md:flex md:space-x-4 mt-4">
          {/* Lot Size */}
          <div className="flex flex-col lg:flex-row lg:items-center w-full overflow-visible">
            <label className="font-semibold text-gray-700 mb-2 md:mb-0 md:mr-4 xs:!mr-[70px]">
              Lot Size
            </label>
            <div className="flex flex-col sm:flex-row sm:space-x-2 overflow-visible">
              <div className="w-full md:w-52">
                <SelectField
                  formik={formik}
                  label=""
                  setFieldValue={formik?.setFieldValue}
                  name="lotSizeMin"
                  isSearchable={false}
                  options={lotSizeOptions}
                  onBlur={formik.handleBlur}
                  placeholder="Select Value"
                  autoFocus={true}
                />
              </div>
              <span className="text-gray-500 flex items-center justify-center">
                to
              </span>
              <div className="w-full md:w-52">
                <SelectField
                  formik={formik}
                  label=""
                  setFieldValue={formik?.setFieldValue}
                  name="lotSizeMax"
                  isSearchable={false}
                  options={lotSizeOptions}
                  onBlur={formik.handleBlur}
                  placeholder="Select Value"
                  autoFocus={true}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="border-t-2 my-2 xs2:flex xs2:items-start" />
      {/* Property Features */}
      <PropertyDisclosure
        formik={formik}
        label=" Property Features"
        list={propertyFeatureCheckboxs}
        isEditPropertyData={isEditPropertyData}
        defaultIsOpen={isValidArray(editPropertyData?.propertyFeaturesFilter)}
      />
      <div className="border-t-2 my-2 xs2:flex xs2:items-start" />
      {/* Community Features */}
      <PropertyDisclosure
        formik={formik}
        label="Community Features"
        list={communityFeaturesCheckboxes}
        isEditPropertyData={isEditPropertyData}
        defaultIsOpen={isValidArray(editPropertyData?.communityFeaturesFilter)}
      />
      <div className="border-t-2 my-2 xs2:flex xs2:items-start" />
      {/* Exterior Features */}
      <PropertyDisclosure
        formik={formik}
        label="Exterior Features"
        list={exteriorFeaturesCheckboxs}
        isEditPropertyData={isEditPropertyData}
        defaultIsOpen={isValidArray(editPropertyData?.exteriorFeaturesFilter)}
      />
      <div className="border-t-2 my-2 xs2:flex xs2:items-start" />
      {/* Interior Features */}
      <PropertyDisclosure
        formik={formik}
        label="Interior Features"
        list={interiorFeaturesCheckboxs}
        isEditPropertyData={isEditPropertyData}
        defaultIsOpen={isValidArray(editPropertyData?.interiorFeaturesFilter)}
      />
      <div className="border-t-2 my-2 xs2:flex xs2:items-start" />
      {/* Style */}
      <PropertyDisclosure
        formik={formik}
        label="Style"
        list={houseStyleCheckboxs}
        isEditPropertyData={isEditPropertyData}
        defaultIsOpen={isValidArray(editPropertyData?.styleFilter)}
      />
      <div className="border-t-2 my-2 xs2:flex xs2:items-start" />
      {/* View */}
      <PropertyDisclosure
        formik={formik}
        label="View"
        list={houseViewCheckboxs}
        isEditPropertyData={isEditPropertyData}
        defaultIsOpen={isValidArray(editPropertyData?.viewFilter)}
      />
      <div className="border-t-2 my-2 xs2:flex xs2:items-start" />
      {/* Waterfront */}
      <PropertyDisclosure
        formik={formik}
        label="Waterfront"
        list={waterFrontCheckboxs}
        isEditPropertyData={isEditPropertyData}
        defaultIsOpen={isValidArray(editPropertyData?.waterFrontFilter)}
      />
      <div className="border-t-2 my-2 xs2:flex xs2:items-start" />
      {/* Financing */}
      <PropertyDisclosure
        formik={formik}
        label="Financing"
        list={financingCheckboxs}
        isEditPropertyData={isEditPropertyData}
        defaultIsOpen={isValidArray(editPropertyData?.financingFilter)}
      />

      <div className="border-t-2 my-3 xs2:flex xs2:items-start" />
      {/* E-Alert */}
      <label className="font-semibold text-gray-700">E-Alert</label>
      <div className="flex flex-wrap items-center w-full mt-4 md:mt-0">
        <label className="font-semibold text-gray-700 mb-2 md:mb-0 w-full md:w-auto md:mr-[90px] ">
          Sent By
        </label>
        <div className="w-full md:w-80">
          <SelectField
            formik={formik}
            label=""
            setFieldValue={formik?.setFieldValue}
            initialvalue={domainOptions?.find(
              (option) => option?.value === formik?.values?.sentBy
            )}
            name="sentBy"
            isSearchable={false}
            options={domainOptions}
            onBlur={formik.handleBlur}
            placeholder="Select Agent"
            autoFocus={true}
          />
        </div>
      </div>

      <div className="flex items-center w-full mt-4 md:mt-3">
        <label className="font-semibold text-gray-700 md:mb-0 w-auto mr-[50px] md:mr-[130px]">
          To
        </label>
        <span className="font-medium">
          {lead?.leadResponse?.[0]?.firstName +
            " " +
            lead?.leadResponse?.[0]?.lastName}
        </span>
      </div>
      <div className="flex flex-wrap items-center w-full mt-4 md:mt-3">
        <label className="font-semibold text-gray-700 mb-2 md:mb-0 w-full md:w-auto md:mr-[45px]">
          Email Subject
        </label>
        <div className="w-full md:w-80">
          <InputField
            id="subject"
            label=""
            name="subject"
            formik={formik}
            type="text"
            onChange={formik.handleChange}
            autoComplete="Email Subject"
            placeholder="Enter Email Subject"
          />
        </div>
      </div>

      <div className="flex flex-wrap items-center w-full mt-4 md:mt-3 mb-3">
        <label className="font-semibold text-gray-700 mb-2 md:mb-0 md:mr-[95px] w-full md:w-auto">
          Mail To
        </label>
        <div>
          {isValidArray(emailList) ? (
            emailList?.map((email, index) => (
              <div key={index}>
                <Checkbox
                  id="emailsToSend"
                  name="emailsToSend"
                  label={email}
                  checked={formik?.values?.emailsToSend?.includes(email)}
                  onChange={(event) =>
                    handleChangeCheckBox(event?.target?.checked, email)
                  }
                />
              </div>
            ))
          ) : (
            <>
              {" "}
              <b>-</b>
              <span className="text-red-500 text-xs">
                ( lead don't have any valid email )
              </span>
            </>
          )}
          <span className="ml-2">
            {formik.errors?.emailsToSend
              ? showFieldError(formik.errors?.emailsToSend)
              : null}
          </span>
        </div>
      </div>

      <div className="flex flex-wrap items-center w-full mt-4 md:mt-0 overflow-visible">
        <label className="font-semibold text-gray-700 mb-2 md:mb-0 w-full md:w-auto md:mr-[70px]">
          Frequency
        </label>
        <div className="relative w-full md:w-80 overflow-visible ">
          <SelectField
            formik={formik}
            label=""
            setFieldValue={formik?.setFieldValue}
            name="frequency"
            isSearchable={false}
            options={frequencyOptions}
            onBlur={formik.handleBlur}
            placeholder="Select Frequency"
            autoFocus={true}
            onDropdownOpen={handleDropdownOpen}
          />
        </div>
      </div>
    </div>
  );
}
